import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import useMediaQuery from '../helpers/useMediaQuery';
import { pushDataLayer, useStores } from '../helpers';
import { withThemeContext } from '../redesign/components/StitchesThemeProvider';
import { styled } from '../stitches.config';
import { observer } from "mobx-react";
import Header from "../components/Article/Header";

import { ReactComponent as ArrowRight } from '../redesign/assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../redesign/assets/icons/arrow-left-nav.svg';
import { preloadArticle } from '../workers/ArticleCache/ArticleCacheClient';
import { HandledEvents } from 'react-swipeable/es/types';
import { h1 } from './commonStyles/Fonts';

interface IArticleSwiper {
    isDarkMode: boolean;
};

/*Config*/
const SWIPE_MARGIN = 0.15; //0-1
const SWIPE_ACTIVE = 0.4; //0-1
const SWIPE_DELAY = 200; //ms

const getHandledEventX = (event: HandledEvents) => {
    let x = 0;
    if (event instanceof TouchEvent)
        x = event.touches[0].clientX;
    else
        x = event.clientX;

    return x;
}

const ArticleSwiper: React.FC<IArticleSwiper> = observer(({ isDarkMode }) => {

    const { ArticleStore, UIStore } = useStores();
    const { UIStatus } = UIStore;

    const isMobile = useMediaQuery('mobile');

    const [visitedPages, setVisitedPages] = useState<string[]>([]);

    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const nextArticle = useMemo(() => {
        if (ArticleStore.fetchedArticle && ArticleStore.fetchedArticle.nextArticleInCategory) {
            //@ts-ignore
            preloadArticle(ArticleStore.fetchedArticle.nextArticleInCategory.slug);

            return ArticleStore.fetchedArticle.nextArticleInCategory.article;
        }
        return undefined;
    }, [ArticleStore.fetchedArticle]);

    const isSwiperEnable = useMemo(() => {
        //Check if mobile
        if (!isMobile)
            return false;

        //Wait for loading
        if (UIStatus.status === "loading" || UIStatus.status === "error")
            return false;

        //Check if nextArticle
        if (!nextArticle)
            return false;

        //Skip when nextArticle already visited
        if (visitedPages.includes(`${nextArticle.OID}`))
            return false;

        return true;
    }, [location, UIStatus, isMobile, nextArticle]);

    const isArticleLoading = (UIStatus.status === "loading" || UIStatus.status === "error");

    const goTo = (path: any) => {
        if (path != -1)
            UIStore.newUserInteraction();
        setSwiped(false);
        navigate(path);
        window.scroll(0, 0);
    }

    const swiperContainer = useRef<HTMLDivElement>(null);
    const [isSwiped, setSwiped] = useState<boolean>(false);
    const [swipeAnimation, setSwipeAnimation] = useState<boolean>(true);
    const [swipeX, setSwipeX] = useState<number>(0);
    const [canGoToNextPage, setCanGoToNextPage] = useState<boolean>(false);

    //Animation after x seconds
    const [ccSwiper, setCCSwiper] = useState<boolean>(false);
    const ccSwiperTimeout = useRef<NodeJS.Timeout>();

    //Move state to ref to get access from timeout
    const ccSwipeX = useRef(swipeX);
    ccSwipeX.current = swipeX;
    const ccIsSwiped = useRef(isSwiped);
    ccIsSwiped.current = isSwiped;

    useEffect(() => {
        if (ccSwiperTimeout.current)
            clearTimeout(ccSwiperTimeout.current);

        if (isArticleLoading)
            return;

        ccSwiperTimeout.current = setTimeout(() => {
            if (!isSwiperEnable || ccIsSwiped.current || ccSwipeX.current < 0 || isArticleLoading)
                return;

            setCCSwiper(true);
            ccSwiperTouch.current = -1;
        }, 10000);
    }, [ArticleStore.fetchedArticle, UIStatus.status]);

    useEffect(() => {
        if (ccSwiper) {
            if (swipeX == 0) {
                setTimeout(() => ccSwiperTouch.current < 0 && setSwipeX(1), 1000);
            } else if (swipeX == 1) {
                setTimeout(() => setCCSwiper(false), 200);
            }
        }
    }, [ccSwiper, swipeX]);

    //Chandle swipe
    const ccSwiperTouch = useRef<number>(-1);

    const { ref: swipeable } = useSwipeable({
        onSwipeStart: ({ initial }) => {
            if (isSwiped || isArticleLoading || !nextArticle)
                return;

            const [startX] = initial;
            const isStartSwipingFromRight = window.innerWidth * (1 - SWIPE_MARGIN) <= startX;
            setSwiped(isStartSwipingFromRight);
            setSwipeAnimation(false);

            setCCSwiper(false);
            ccSwiperTouch.current = -1;
        },
        onSwiping: ({ deltaX, event }) => {
            if (!isSwiped || canGoToNextPage)
                return;

            if (ccSwiperTouch.current < 0)
                setSwipeX(deltaX);
            else {
                let currentX = getHandledEventX(event);
                setSwipeX(-(window.innerWidth - currentX));
            }
        },
        onSwiped: ({ absX }) => {
            if (!isSwiped || canGoToNextPage)
                return;

            setSwipeAnimation(true);

            if (absX >= window.innerWidth * SWIPE_ACTIVE)
                setCanGoToNextPage(true);
            else {
                setSwipeX(0);
                setSwiped(false);
            }
        },
        onTouchStartOrOnMouseDown: ({ event }) => {
            if (ccSwiper && !isSwiped &&
                swiperContainer.current && (event.target === swiperContainer.current || swiperContainer.current.contains(event.target as Node))
            ) {
                let handleTouchX = getHandledEventX(event);
                if (handleTouchX <= 0)
                    return;
                ccSwiperTouch.current = handleTouchX;

                setSwiped(true);
                setCCSwiper(false);
                setSwipeAnimation(false);
                setSwipeX(-(window.innerWidth - handleTouchX));
            }
        },
        preventScrollOnSwipe: isSwiped
    });

    //GoToNextPage
    useEffect(() => {
        if (!canGoToNextPage)
            return;

        setSwipeAnimation(true);
        setSwipeX(-window.innerWidth);
        setTimeout(() => {
            setSwipeAnimation(false);
            setSwipeX(0);
            setTimeout(() => {
                setSwipeAnimation(true);
                goTo(`/${nextArticle.slug}`);
                //Analityka
                window._gaq.push(["_trackEvent", "ANALYTICS", "article-swipe-next"]);
                pushDataLayer({
                    event: "analyticsEvent",
                    eventCategory: "article-swipe-next",
                })
            }, 50);
        }, SWIPE_DELAY + 100);
    }, [canGoToNextPage]);

    useEffect(() => {
        showArrows(true);
        setSwipeX(0);
        setCanGoToNextPage(false);
        setSwipeAnimation(true);
        setVisitedPages([
            ...visitedPages,
            location.pathname.split(',')[0].replace(/^\//, '')
        ]);
    }, [location]);

    //Change nav zIndex
    useEffect(() => {
        const navBar = document.querySelector('.nav-fixed') as HTMLElement | null;
        if (!navBar)
            return;

        if (swipeX < 0)
            navBar.style.zIndex = '15';
        else
            navBar.style.removeProperty('zIndex');
    }, [swipeX, isSwiped]);

    //Run swipeable
    const [isInit, setInit] = useState<boolean>(false);
    useEffect(() => {
        const handlePageHide = () => {
            setSwipeX(0);
        }

        if (!isSwiperEnable || isInit)
            return;

        swipeable(document.body);
        setInit(true);
        window.addEventListener('pagehide', handlePageHide);
        return () => window.removeEventListener('pagehide', handlePageHide);
    }, [isSwiperEnable]);

    //Arrows
    const [hasArrows, showArrows] = useState<boolean>(true);
    useEffect(() => {
        const hideArrows = () => {
            if (isArticleLoading)
                return;
            const firstImage = document.querySelectorAll('.figure-wrapper-art-top');
            let displayArrows = window.scrollY < window.innerHeight;
            if (firstImage.length > 0) {
                const imageRects = firstImage[0].getBoundingClientRect();
                displayArrows = imageRects.top > 0;
            }
            showArrows(displayArrows);
        };
        window.addEventListener('scroll', hideArrows, {
            passive: true
        });

        return () => window.removeEventListener('scroll', hideArrows);
    }, []);

    const showLeftArrow = useMemo(() => {
        const currentOID = location.pathname.split(',')[0].replace(/^\//, '');
        if (visitedPages.indexOf(currentOID) == 0)
            return false;
        return true;
    }, [location, ArticleStore, visitedPages]);

    const showRightArrow = useMemo(() => {
        return !!nextArticle;
    }, [nextArticle, location]);

    const isSmallNav = !!document.querySelector('[data-analytics-name="nav-main-logo-hiding-article"]');
    const isBigNav = !(document.body.classList.contains('scrolling-down') || document.body.classList.contains('scrolling-up'));

    //Disable swiping on desktop && exitScreen
    if (!isMobile || searchParams.get('__exsc'))
        return null;

    return (<>
        {!isArticleLoading && showLeftArrow && <ArrowWrapper show={hasArrows} side="left" onClick={() => goTo(-1)} data-analytics-name="article-click-back">
            <ArrowLeft />
        </ArrowWrapper>}
        {!isArticleLoading && showRightArrow && <ArrowWrapper show={hasArrows} side="right" onClick={() => goTo(`/${nextArticle.slug}`)} data-analytics-name="article-click-next">
            <ArrowRight />
        </ArrowWrapper>}
        {isSwiperEnable && (
            <>
                <SwiperArticleContainer
                    ref={swiperContainer}
                    style={{
                        transform: !ccSwiper ? `translateX(${swipeX}px)` : '',
                        transition: 'transform ' + (ccSwiper ? `350ms` : !swipeAnimation ? `0ms` : `${SWIPE_DELAY}ms`),
                    }}
                    ccSwiper={ccSwiper}
                    ccSwiperShow={swipeX == 0}
                >
                    <div
                        style={{
                            paddingTop: `${ccSwiper ? 0 : !isSmallNav ? Math.max(128 - window.scrollY, 0) : 54}px`
                        }}
                    >
                        <Header article={{
                            BodyPart: [
                                {
                                    type: "picture",
                                    data: nextArticle.picture
                                }
                            ],
                            TitlePart: [
                                {
                                    type: "post-title",
                                    data: nextArticle.title,
                                    as: "p"
                                },
                                {
                                    type: "author-date",
                                    data: {
                                        article: {
                                            date: nextArticle.date,
                                            reading_time: nextArticle.reading_time
                                        },
                                        author: { ...nextArticle.author }
                                    }
                                },
                                {
                                    type: "lead",
                                    data: nextArticle.lead
                                }
                            ],
                            ...nextArticle
                        }} isDarkMode={isDarkMode} />
                        <div className="container">
                            <FakeImage />
                        </div>
                    </div>
                </SwiperArticleContainer>
            </>
        )}
    </>);

});

export default withThemeContext(ArticleSwiper);

const SwiperArticleContainer = styled('div', {
    display: 'block',
    position: 'fixed',
    top: 0,
    zIndex: 14,
    left: '100vw',
    width: '100vw',
    height: '100vh',
    background: '$grayscale0',
    color: '$grayscale100',
    '.avatar': {
        width: '72px',
        height: '72px'
    },
    [`.${h1()}`]: {
        margin: '0.67em 0'
    },
    variants: {
        ccSwiper: {
            true: {
                zIndex: '15',
                height: '70vh',
                top: '15%',
                overflow: 'hidden',
                borderRadius: '1.25rem',
                transformOrigin: 'left bottom',
                boxShadow: '0px 0px 16px 0px var(--colors-primary)',
                '& > div': {
                    transform: 'scale(0.7)',
                    transformOrigin: '0px 0px',
                    position: 'relative'
                }
            }
        },
        ccSwiperShow: {
            true: {
                transform: 'rotate(-15deg)'
            },
            false: {
                transform: 'translateX(2rem)'
            }
        }
    }
});

const FakeImage = styled('div', {
    width: '100%',
    background: '$grayscale25',
    '&::before': {
        content: '',
        display: 'block',
        paddingTop: '85%'
    }
});

const ArrowWrapper = styled('div', {
    position: 'fixed',
    zIndex: 13,
    width: '40px',
    height: '40px',
    background: '$grayscale0Fixed',
    border: '1px solid $primary',
    right: '8px',
    top: 'calc(50% - 20px)',
    display: 'flex',
    padding: '8px',
    transition: '0.2s',
    borderRadius: '100%',
    'svg': {
        margin: 'auto',
        width: '100%',
        height: '100%',
        'path': {
            fill: '$primary !important'
        }
    },
    variants: {
        show: {
            true: {},
            false: {}
        },
        side: {
            left: {
                left: '8px'
            },
            right: {
                right: '8px'
            }
        }
    },
    compoundVariants: [
        {
            show: 'false',
            side: 'left',
            css: {
                left: '-40px'
            }
        },
        {
            show: 'false',
            side: 'right',
            css: {
                right: '-40px'
            }
        }
    ]
});