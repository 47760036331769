import React from "react";
import { styled } from "../../stitches.config";

import TipFormStandalone from "../Modal/payment/TipFormStandalone";
import { IS_FEATURE_TIP } from "../../components/common/constants/constants";

const PartnershipInfo = React.lazy(() => import(/* webpackChunkName: "redesign__PartnershipInfo" */ "../PartnershipInfo"));
const AuthorBlock = React.lazy(() => import(/* webpackChunkName: "redesign__AuthorBlock" */ "../AuthorBlock"));

interface ArticleAuthorBottomProps {
    article: ArticleAuthor;
    partnership?: string;
    isPodcast?: boolean;
    hasTip?: boolean;
}

const ArticleAuthorBottom: React.FC<ArticleAuthorBottomProps> = ({
    article,
    partnership = "",
    isPodcast,
    hasTip
}) => {
    const isMobile = window.innerWidth >= 768;
    return (
        <>
            {article?.author?.url ? (
                <StyledArticleAuthorBottom>
                    <div className={isMobile ? "container" : "full-width"}>
                        <div className="col-md-8 author-bottom-semi">
                            <Wrapper>
                                <AuthorBlock
                                    isPodcast={isPodcast}
                                    author={article.author}
                                    border={true}
                                    authorSize={"big"}
                                    articleDate={article.date}
                                    articleUpdateDate={article.update_date}
                                    articleReadingTime={article.reading_time}
                                />
                                {article?.author?.isRecentlyActive && hasTip && (
                                    <TipFormStandalone
                                        allAuthors={[article?.author.OID]}
                                        descriptionText="Cała kwota trafia na konto dziennikarzy i dziennikarek. Możesz przekazać im też krótką wiadomość."
                                    />
                                )}
                            </Wrapper>
                            {!isPodcast && (
                                <>{partnership && <PartnershipInfo partner={partnership} />}</>
                            )}
                        </div>
                    </div>
                </StyledArticleAuthorBottom>
            ) : null}
        </>
    );
};

const StyledArticleAuthorBottom = styled("div", {
    width: "100%",
    marginTop: "40px",
    "@bp4": {
        marginTop: "68px",
    },
    "& .col-md-8": {
        padding: 0,
    },
    "& .full-width": {
        width: "100%",
        margin: 0,
    },
    "& .podcastAuthor": {
        backgroundColor: "$grayscale10 !important",
    },
});

const Wrapper = styled("div", {
    marginBottom: "8px",
});

export default ArticleAuthorBottom;
