import React, { useEffect, useRef } from "react";
import { defaultColors, styled } from "../../stitches.config";
import { body } from "../commonStyles/Fonts";
interface Field {
  name: string;
  link: string;
}

interface Props {
  category: Field;
  subCategory?: Field;
  invert?: boolean;
  allWhite?: boolean;
}

const getRenderBrand = () => {
  switch (window.BRAND) {
    case "natemat":
      return (
        <BrandTitle brand="natemat">
          na<span>:</span>Temat
        </BrandTitle>
      );
    case "innpoland":
      return (
        <BrandTitle brand="innpoland">
          INN<span>:</span>Poland
        </BrandTitle>
      );
    case "mamadu":
      return (
        <BrandTitle brand="mamadu">
          mama<span>:</span>Du
        </BrandTitle>
      );
    case "dadhero":
      return (
        <BrandTitle brand="dadhero">
          dad<span>:</span>HERO
        </BrandTitle>
      );
    case "aszdziennik":
      return (
        <BrandTitle brand="aszdziennik">
          ASZ<span>:</span>dziennik
        </BrandTitle>
      );
  }
};

const Breadcrumbs: React.FC<Props> = ({
  category,
  subCategory,
  invert,
  allWhite,
}) => {
  const getLogoType = () => {
    if (invert) return "invert";
    if (allWhite) return "light";
  };
  const getLinkNameType = () => {
    if (allWhite) return "light";
    if (subCategory) return invert ? "invert" : "secondary";
    return "main";
  };
  return (
    <Wrapper className="breadcrumbs">
      <ListWrapper>
        <li>
          <Logo href='/' className={body()} type={getLogoType()}>
            {getRenderBrand()}
          </Logo>
        </li>
        <li>
          <Arrow className={subCategory ? "primary-svg" : "gray-svg"}>
            <svg
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.6788 0.446411L2.11311 1.0121L5.10098 3.99996L2.11311 6.98783L2.6788 7.55352L6.23235 3.99996L2.6788 0.446411ZM2.25453 6.98783L5.2424 3.99996L5.24242 3.99998L2.25455 6.98785L2.25453 6.98783ZM2.25455 1.01211L2.67882 0.58785L2.6788 0.587832L2.25453 1.0121L2.25455 1.01211Z"
              />
            </svg>
          </Arrow>
          <LinkName
            href={category.link}
            className={body()}
            type={getLinkNameType()}
          >
            {category.name}
          </LinkName>
        </li>
        {subCategory && (
          <li>
            <Arrow className="gray-svg">
              <svg
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.6788 0.446411L2.11311 1.0121L5.10098 3.99996L2.11311 6.98783L2.6788 7.55352L6.23235 3.99996L2.6788 0.446411ZM2.25453 6.98783L5.2424 3.99996L5.24242 3.99998L2.25455 6.98785L2.25453 6.98783ZM2.25455 1.01211L2.67882 0.58785L2.6788 0.587832L2.25453 1.0121L2.25455 1.01211Z"
                />
              </svg>
            </Arrow>
            <LinkName
              href={subCategory.link || window.location.pathname}
              className={body()}
              type="main"
            >
              {subCategory.name}
            </LinkName>
          </li>
        )}
      </ListWrapper>
    </Wrapper>
  );
};

const ListWrapper = styled("ul", {
  display: "flex !important",
  alignItems: "center",
});

const Wrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  img: {
    margin: "0 8px",
    width: "8px",
    height: "8px",
  },
  ul: {
    display: "inline-block",
    paddingLeft: 0,
  },
  li: {
    display: "flex",
    alignItems: "center",
  },
  fontWeight: "600 !important",
  ".primary-svg": {
    svg: {
      fill: "$primary",
    },
  },
  ".gray-svg": {
    svg: {
      fill: "#8D8D8D",
    },
  },
});

const Arrow = styled("div", {
  margin: "0 8px",
});

const Logo = styled("a", {
  color: "$grayscale0",
  variants: {
    type: {
      invert: {
        color: "$grayscale100",
      },
      light: {
        color: defaultColors.grayscale0,
      },
    },
  },
});

const BrandTitle = styled("span", {
  variants: {
    brand: {
      natemat: {
        span: {
          color: "$natematPrimary",
        },
      },
      aszdziennik: {
        span: {
          color: "$aszdziennikPrimary",
        },
      },
      dadhero: {
        span: {
          color: "$dadheroPrimary",
        },
      },
      mamadu: {
        span: {
          color: "$mamaduPrimary",
        },
      },
      innpoland: {
        span: {
          color: "$innpolandPrimary",
        },
      },
    },
  },
});

const LinkName = styled("a", {
  variants: {
    type: {
      main: {
        color: `${defaultColors.grayscale75} !important`,
      },
      secondary: {
        color: "$grayscale0",
      },
      invert: {
        color: "$grayscale100 !important",
      },
      light: {
        color: defaultColors.grayscale0,
      },
    },
  },
});

export default Breadcrumbs;
