import React, { useRef, useState } from "react";
import TweetEmbed from "react-tweet-embed";
import { styled } from "../../../../stitches.config";

import WithLazyLoad from "../../../HOCs/WithLazyLoad";
import AnimateHeightWrapper, { AnimateWrapperButton } from "../../Parts/Brid/common/components/AnimateHeightWrapper";

import shareIcon from "../../../../redesign/assets/icons/socialbutton-share.svg"

const TwitterPost = (props) => {

  const [visibility, setVisibility] = useState(false)

  const ref = (node) => {
    if (node !== null) {

      let interlvalId = setInterval(() => {
        let height = node._div.children[0]?.getBoundingClientRect().height;
        if (height != undefined && height > 0) {
          if (height > 450) {
            setVisibility(true);
          }

          clearInterval(interlvalId);
        }
      }, 1000);
    }
  };


  return (
    <WithLazyLoad options={{
      height: 480,
      once: true,
      offset: 600
    }}>
      <AnimateHeightWrapper type={AnimateWrapperButton.ShowMore} height={450} visibility={visibility}>
        <CenteredEmbed>
          <TweetEmbed tweetId={props.dataId} options={{ lang: "pl" }} id={props.dataId} ref={ref} />
        </CenteredEmbed>
      </AnimateHeightWrapper>

    </WithLazyLoad>
  );
}

export default TwitterPost;

const CenteredEmbed = styled("div", {
  "& > div > .twitter-tweet": {
    margin: "10px auto !important"
  },
  minHeight: "250px",
  positon: "relative",
  "&::before": {
    minHeight: "100px",
    height: "100%",
    content: "",
    background: `transparent url(${shareIcon}) no-repeat center center`,
    backgroundSize: "60px",
    opacity: 0.4,
    top: "0",
    left: "0",
    right: "0",
    position: "absolute",
    zIndex: 0
  },
  iframe: {
    position: "relative !important"
  }
})