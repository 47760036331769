import React from "react";
import { styled } from "../../../stitches.config";

const FooterPlaceholder = styled("div", {
    minHeight: "884px",
    "@bp4": {
        minHeight: "344px",
    }
});

export default FooterPlaceholder;