import React, { Suspense } from "react";
import { styled } from "../../../../stitches.config";

import NavbarBottomPlaceholder from "./NavbarBottomPlaceholder";

const NavbarBottomFull = React.lazy(()=> import("./NavbarBottom"));

const NavbarBottom: React.FC = ()=>{

    return (
        <FixedContainer>
            <Suspense
                fallback={<NavbarBottomPlaceholder/>}
            >
                <NavbarBottomFull/>
            </Suspense>
        </FixedContainer>
    );

};

const FixedContainer = styled("div", {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 999998
})

export default NavbarBottom;