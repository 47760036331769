import React, { ComponentType, PropsWithChildren, memo } from "react";
import ErrorBoundary from "../ErrorBoundary";

const SlateBlockBizon: React.FC<PropsWithChildren> = ({ children }) => {

    return (
        <ErrorBoundary>
            {children}
        </ErrorBoundary>
    );

};

export default SlateBlockBizon;