import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import openIDB from "./IDB";
import { Provider } from "mobx-react";
import { BrowserRouter } from "react-router-dom";

import RootStore from "./stores/RootStore";
import * as serviceWorker from './serviceWorker';

import { DeepViewChecker } from "./helpers/DeepViewChecker";

import './redesign/ExitScreen';

const stores = {
  RootStore: RootStore,
  ArticleStore: RootStore.ArticleStore,
  CategoryStore: RootStore.CategoryStore,
  UIStore: RootStore.UIStore,
  ReadLaterStore: RootStore.ReadLaterStore,
  TopicStore: RootStore.TopicStore,
  UserStore: RootStore.UserStore,
  AdsStore: RootStore.AdsStore,
  PodcastStore: RootStore.PodcastStore
};

ReactDOM.render(
  <div className="body-v2">
    <Provider {...stores}>
      <BrowserRouter>
        <DeepViewChecker />
        <App />
      </BrowserRouter>
    </Provider>
  </div>,
  document.getElementById("root")
);

openIDB();
serviceWorker.register();