import React, { useState, useRef, useEffect } from 'react';
import { styled, mainColor, css } from "../../stitches.config";
import { EmbeddedVideo } from "react-facebook";
import OriDomi from "oridomi"

import Link from "../../components/Link";
import ArticleLinks from "../../components/common/ArticleLinks";

import PlayIcon from "../assets/icons/player-icon.svg";
import { ReactComponent as PlayIconWithoutColor } from "../assets/icons/player-icon-active-without-color.svg";
import PlayIconActive from "../assets/icons/player-icon-active.svg";
import Media from "../../components/common/Media"
import Balls from '../../redesign/components/Balls';
import IconMamadu from "../assets/icons/jakosc_mamaDu_etykieta.svg";
import HotNews from "../assets/icons/hot-news.svg";

import UniversalEmbedBox from "./UniversalEmbedBox";

const TextOnPicture = props => {

  // ustawianie ratio pod One Super Mobile
  const windowWidth = window.innerWidth;
  // zdjęcie musi mieć ratio 3:4
  const ratioOneSuperMobileHeight = windowWidth * 1.33
  // sprawdzanie, czy jest zdjęcie mobile 
  const backgroundImageMobile = ((props.media && props.media['background-image-mobile']) ? props.media['background-image-mobile'] : {});
  const isMobileOptionActive = (backgroundImageMobile?.hash && backgroundImageMobile?.hash.length > 0 ? true : false)

  const [progress, setProgress] = useState(0)
  const ref = useRef()
  const fold = useRef();
  const { article, aspectRatio: { mobile = 1.78, desktop = 1.67, mobileIsSuperOne = 0.75 } = {} } = props;
  const isDesktop = window.innerWidth > 996;
  const partner = (article.partner ? <Partner>{article.partner}</Partner> : null);
  const label = (article?.category && article.category?.name ? <Label><Balls /> {article.category.name}</Label> : null);
  const player = (parseInt(article.player) === 1 ? <Player><PlayIconWithoutColor /></Player> : null);
  const iconTesting = (article.icon === "TESTUJEMY" ? <IconTesting></IconTesting> : null);

  var imageHeight = (props.imageHeight ? props.imageHeight : 350);
  var imageWidth = 0;

  // if (props.preserveImageRatio && window.innerWidth > 991) {
  //   imageHeight = 0;
  //   imageWidth = 1170;
  // }
  var imgUrl = article.picture.img || '/';
  imgUrl = imgUrl.replace("780,0,0,0", `${imageWidth},${imageHeight},0,0`);

  var partnerLogo = null;
  if (article.partner_logo) {
    var partnerLogoImg = window.MEDIA_DOMAIN + '/' + article.partner_logo.replace('.', ',90,90,0,0.');
    partnerLogo = (
      <ArticlePartner>
        Partnerem materiału jest
        <img src={partnerLogoImg} alt="" />
      </ArticlePartner>
    );
  }

  const aspectRatio = isDesktop ? desktop : mobile;
  const aspectRatioMobileOption = isDesktop ? desktop : mobileIsSuperOne;

  const onLoadFinished = () => setTimeout(() => {
    if (props.samsung) {
      fold.current = new OriDomi(ref.current, {
        vPanels: props.samsung.fold ? 2 : 1,
        hPanels: props.samsung.flip ? 2 : 1,
        shading: false,
        maxAngle: 70,
        touchEnabled: window.innerWidth > 996 ? true : false,
        speed: 0
      });
      fold.current.accordion(window.innerWidth > 996 ? 25 : 0, props.samsung.flip ? 'top' : 'left')
    }
  }, 0)

  const handleScroll = () => {
    if (window.innerWidth > 996) return;
    const { bottom } = ref.current.getBoundingClientRect()
    if (bottom > 0 && bottom < window.innerHeight) {
      let prog = Math.round(Math.abs(bottom - window.innerHeight) / window.innerHeight * 100)
      if (progress != prog) {
        setProgress(prog)
      }
      fold.current?.accordion && fold.current.accordion(progress)
    }
  }

  useEffect(() => {
    if (props.samsung) {
      window.addEventListener("scroll", handleScroll)
    }
    return () => {
      if (props.samsung) {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [progress]);

  return (
    <>
      {article.embed && article.embed.type ?
        <UniversalEmbedBox article={article} style={props.style} isEmbedTextOnPhoto={true} isOneSuper={props.isOneSuper} />
        :
        <BoxWrapper
          ref={ref} className={"text-on-picture style--" + props.style + (props.isOneSuper && isMobileOptionActive ? " text-on-picture-one-super" : "")}
          css={{
            "$$imageHeight": imageHeight + 'px',
            "$$ratioOneSuperMobileHeight": ratioOneSuperMobileHeight + 'px'
          }}
          preserveImageRatio={props.preserveImageRatio}>
          <Link to={article.url}>
            <ImageWrapper>
              <Media
                media={props.media}
                src={article.picture.img}
                preserveRatio={props.preserveImageRatio ? true : false}
                aspectRatio={props.preserveImageRatio ? undefined : aspectRatio}
                aspectRatioMobileOption={props.preserveImageRatio && props.isOneSuper ? undefined : aspectRatioMobileOption}
                fetchpriority={props.fetchpriority}
                onLoadFinished={onLoadFinished}
              />
              {player}
              {partner}
              {iconTesting}
              {partnerLogo}
              {article?.iconHotNews && <IconHotNews />}
            </ImageWrapper>
          </Link>

          <Link className="link-footer" to={article.url}>
            <Footer className={article.show_links && article.show_links !== 'off' ? 'show-links top-link-footer' : 'top-link-footer'} size={props.size} >
              <div className={props.size == 'lg' ? 'container' : ''}>
                <FooterSpacer size={props.size}>
                  <Title className="text-on-picture__title" size={props.size}>
                    <span className={"embedLabel" + " embedLabel--" + article.categoryColor}>{label}</span>
                    {article.pile_title || article.title}
                  </Title>
                  <ArticleLinks links={article.links} show={article.show_links} />
                </FooterSpacer>
              </div>
            </Footer>
          </Link>

        </BoxWrapper>
      }
    </>
  );
};

const FooterSpacer = styled("div", {
  "@bp4": {
    width: "75%"
  },
  variants: {
    size: {
      "lg": {
        "@bp4": {
          width: "50%"
        }
      }
    }
  }
});

const Title = styled("span", {
  display: "inline",
  fontWeight: 700,
  color: "$grayscale100Fixed",
  backgroundColor: "$grayscale0Fixed",
  fontSize: "24px",
  letterSpacing: "0.2px",
  lineHeigjt: 32,
  position: "relative",
  pointerEvents: "none",
  width: "100%",
  whiteSpace: "pre-wrap",
  "@bp3": {
    fontSize: "24px",
    lineHeight: "32px"
  },
  "@bp4": {
    padding: "0 2px"
  },
  "& > svg": {
    display: "block",
    position: "absolute",
    top: "-11px",
    left: "-39px",
    height: "48px",
    width: "22px",
    "@bp4": {
      display: "none"
    }
  },
  ".embedLabel": Object.entries(mainColor).reduce((styles, [brand, colors]) => {
    styles[`&--${brand} span`] = {
      background: `${colors.primary} !important`
    }
    return styles;
  }, {
    "&--red span": {
      background: "#EA3A3E !important"
    },
    "&--black span": {
      background: "$grayscale100Fixed !important"
    },
    "&--primary span": {
      background: "$primary !important"
    }
  }),
  variants: {
    size: {
      "lg": {
        "@bp3": {
          fontSize: "38px",
          lineHeight: "52px"
        }
      },
      "sm": {
        "@bp4": {
          fontSize: "15px",
          lineHeight: "18px",
          "& > svg": {
            display: "none"
          }
        }
      }
    }
  }
});

const Label = styled("span", {
  display: "flex",
  alignSelf: "center",
  position: "absolute",
  top: "-34px",
  whiteSpace: "nowrap",
  padding: "2px 4px 2px 16px",
  background: "$primary",
  color: "#fff",
  lineHeight: "16px",
  fontSize: "12px",
  fontWeight: 800,
  letterSpacing: "0.8px",
  zIndex: 4,
  "@bp4": {
    left: "-2px",
    top: "-30px"
  }
});

const IconTesting = styled("div", {
  position: "absolute",
  right: "10px",
  top: "0px",
  width: "60px",
  height: "90px",
  backgroundImage: `url(${IconMamadu})`,
  backgroundSize: "cover",
  zIndex: 10
});

const BoxWrapper = styled("div", {
  position: "relative",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  marginBottom: "24px",
  "@bp3": {
    overflow: "hidden",
    marginBottom: "32px"
  },
  "@bp4": {
    minHeight: "$$imageHeight",
    paddingBottom: 0
  },
  "& > a": {
    display: "block",
    textDecoration: "none",
    zIndex: 1,
    inset: 0,
    minWidth: "100%",
    position: "static",
    height: "$$imageHeight",
    "@bp4": {
      height: "auto",
      position: "absolute"
    }
  },
  ".link-footer": {
    height: "auto",
    position: "static"
  },
  "circle": {
    transition: "all 0.2s linear"
  },
  "&:hover": {
    [`& ${Title}`]: {
      color: "$primary",
      textDecoration: "none",
      cursor: "pointer"
    },
    "circle": {
      fill: "#000",
      stroke: "#000"
    }
  },
  /* Top super mobile */
  "&.text-on-picture-one-super": {
    "@mobile": {
      height: "$$ratioOneSuperMobileHeight",
      "a": {
        height: "$$ratioOneSuperMobileHeight"
      }
    },
    ".link-footer": {
      height: "auto !important"
    }
  },
  /* Modyfikatory z BO */
  "&.style--red": {
    [`& ${Title}`]: {
      color: "#fff",
      background: "#EA3A3E",
      boxShadow: "2px 0 0 red, -2px 0 0 #EA3A3E"
    },
    [`& ${Label}`]: {
      color: "#fff",
      background: "#EA3A3E"
    },
    "&:hover": {
      [`& ${Title}`]: {
        color: "#ffeeee"
      }
    }
  },
  "&.style--black": {
    [`& ${Title}`]: {
      color: "#fff",
      background: "#131212",
      boxShadow: "2px 0 0 red, -2px 0 0 #131212"
    },
    [`& ${Label}`]: {
      color: "#fff",
      background: "#EA3A3E"
    },
    "&:hover": {
      [`& ${Title}`]: {
        color: "#ffeeee"
      }
    }
  },
  variants: {
    size: {
      "lg": {
        "& > a": {
          position: "absolute"
        }
      }
    }
  }
});

const ImageWrapper = styled("div", {
  positiom: "relative",
  height: "100%",
  "img": {
    width: "100%",
    height: "350px",
    minHeight: "100%",
    objectFit: "cover"
  },
  "& > .preserveImageRatioImage": {
    display: "none"
  },
  "@bp4": {
    display: "block",
    "& > .preserveImageRatioImage": {
      width: "100%",
      display: "none",
      minHeight: "$$imageHeight"
    }
  },
  variants: {
    preserveImageRatio: {
      true: {
        "@bp4": {
          "& > .preserveImageRatioImage": {
            display: "block",
            minHeight: "150px"
          }
        }
      }
    }
  }
});

const Partner = styled("span", {
  display: "block",
  position: "absolute",
  left: 0,
  top: 0,
  padding: "0px 8px",
  backgroundColor: "#D8D8D8",
  color: "#8B8B8B",
  lineHeight: "16px",
  fontSize: "11px",
  zIndex: 4
});

const Player = styled("span", {
  display: "block",
  position: "absolute",
  inset: 0,
  backgroundSize: "64px auto",
  zIndex: 5,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "svg": {
    ".color-border-path": {
      fill: "#fff"
    }
  },
  "&:hover": {
    "svg": {
      ".color-path": {
        fill: "$primary"
      },
      ".color-border-path": {
        fill: "$primary"
      }
    }
  }
});

const ArticlePartner = styled("span", {
  fontSize: "10px",
  color: "#eee",
  position: "absolute",
  right: "10px",
  top: "10px",
  "img": {
    width: "40px",
    marginLeft: "6px"
  }
});

const IconHotNews = styled("div", {
  position: "absolute",
  right: "0px",
  bottom: "0px",
  width: "36px",
  height: "36px",
  backgroundImage: `url(${HotNews})`,
  backgroundSize: "16px",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundColor: "#EA3A3E",
  zIndex: 3
});

const Footer = styled("div", {
  display: "block",
  marginTop: "16px",
  zIndex: 2,
  pointerEvents: "none",
  "@mobile": {
    ".embedLabel span": {
      top: "-56px",
      left: "16px"
    }
  },
  "@bp4": {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    padding: "0 26px",
    paddingBottom: "24px",
    /*na potrzeby art. powiązanych desktop */
    "&.show-links": {
      paddingLeft: "250px"
    },
    /*na potrzeby art. powiązanych desktop na prawej / Marek Belka*/
    "&.show-links--on-right": {
      display: "flex",
      alignItems: "flex-end",
      padding: "50px 30px 48px 65px"
    }
  },
  variants: {
    size: {
      "lg": {
        "@bp4": {
          position: "absolute",
          left: 0,
          right: 0,
          bottom: "24px",
          ".embedLabel span": {
            top: "-34px",
            left: "unset"
          },
          padding: 0,
          paddingBottom: "80px"
        }
      },
      /* opcja z małym tekstem, wykorzystywana w special 1d/1m */
      "sm": {
        "@bp4": {
          padding: "0 15px 35px 15px"
        }
      }
    }
  }
});

export default TextOnPicture