import { Formik } from "formik";
import React, { useEffect } from "react";
import Link from "../../../components/Link";
import useModal from "../../../helpers/useModal";
import { defaultColors, styled } from "../../../stitches.config";
import {
  bodyLarge,
  bodyLargeStrong,
  bodyStrong,
  h3,
} from "../../commonStyles/Fonts";
import CustomLink from "../../Link";
import { PaymentStatus } from "../../types/Payment";
import * as Yup from "yup";
import { withThemeContext } from "../../components/StitchesThemeProvider";
import checkIcon from "../../../../src/assets/img/white_check.svg";
import Avatar from "../../atoms/Avatar";
import PhotoOnLeft from "../../components/PhotoOnLeft";
import axios from "axios";
import { SuccessTipModal } from "./TipModal";

interface Props {
  onPayButtonClick: (amount: number, email: string, message?: string) => void;
  colorMode?: string;
  descriptionText: string;
  isManyAuthors?: boolean;
}

const defaultAmounts = [
  {
    amountText: "2 zł",
    amountValue: 2,
  },
  {
    amountText: "5 zł",
    amountValue: 5,
  },
  {
    amountText: "10 zł",
    amountValue: 10,
  },
  {
    amountText: "Inne",
  },
];

const FailedTipModal = () => {
  return (
    <MessageTipWrapper>
      <h3 className={h3()}>Coś poszło nie tak</h3>
    </MessageTipWrapper>
  );
};

type TipFromValues = {
  email: string;
  message: string;
  amount: number;
  privacyPolicy: boolean;
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Pole wymagane")
    .email("Wprowadź poprawny adres e-mail"),
  amount: Yup.number().required("Pole wymagane").min(1, "Pole wymagane"),
  message: Yup.string(),
  privacyPolicy: Yup.boolean()
    .required("Musisz zaakceptować warunki")
    .oneOf([true], "Musisz zaakceptować warunki."),
});

const TipFormBasic: React.FC<Props> = ({ onPayButtonClick, colorMode, descriptionText, isManyAuthors }) => {
  const { modalData } = useModal();
  const [selectedAmountText, setSelectedAmountText] = React.useState<string>(
    defaultAmounts[1].amountText
  );

  const initialValues: TipFromValues = {
    email: "",
    message: "",
    amount: 5,
    privacyPolicy: false,
  };
  const isDarkMode = colorMode === "dark";

  const isOtherValue = selectedAmountText === "Inne";

  switch (modalData?.paymentStatus) {
    case PaymentStatus.SUCCESS:
      if (modalData?.paymentEditorId)
        return (
          <Wrapper>
            <SuccessTipModal authorId={modalData?.paymentEditorId} />
          </Wrapper>
        );
      break;
    case PaymentStatus.FAILED:
      return (
        <Wrapper>
          <FailedTipModal />
        </Wrapper>
      );
      break;
  }

  const onSubmit = ({
    email,
    amount,
    message,
    privacyPolicy,
  }: TipFromValues) => {
    onPayButtonClick(amount, email, message);
  };
  return (
    <Wrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          handleSubmit,
        }) => (
          <>
            <TextColumn>
              {isManyAuthors ?
                <h3 className={h3()}>
                  Podobają Ci się nasze artykuły?
                  <br />
                  Możesz zostawić nam mały napiwek.
                </h3>
                :
                <h3 className={h3()}>
                  Podobają Ci się moje artykuły?
                  <br />
                  Możesz zostawić mi mały napiwek.
                </h3>
              }
              <p className={bodyLarge()} style={{ fontWeight: 600 }}>
                {descriptionText}
              </p>
              <CustomLink
                text="Sprawdź, jak to działa"
                url="/439801,natemat-docenione-przez-google-stworzymy-system-napiwkow-dla-dziennikarzy"
                size="monochromatic"
              />
              <p className={bodyLargeStrong()} style={{ fontWeight: 600 }}>
                Kwota napiwku
              </p>
              <AmountWrapper>
                {defaultAmounts.map(({ amountText, amountValue }, index) => {
                  return (
                    <AmountButton
                      key={index}
                      type="button"
                      isDarkMode={isDarkMode}
                      selected={selectedAmountText === amountText}
                      onClick={() => {
                        setSelectedAmountText(amountText);
                        amountValue && setFieldValue("amount", amountValue);
                      }}
                    >
                      {amountText}
                    </AmountButton>
                  );
                })}
              </AmountWrapper>
              {isOtherValue && (
                <>
                  <p className={bodyLargeStrong()}>Podaj kwotę</p>
                  <Input
                    type="number"
                    name="amount"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.amount}
                    isDarkMode={isDarkMode}
                  />
                </>
              )}
              <Error>{errors.amount && touched.amount && errors.amount}</Error>
            </TextColumn>
            <TipColumn>
              <form onSubmit={handleSubmit}>
                <p className={bodyLargeStrong()}>
                  {isManyAuthors ? "Wiadomość do autorów (opcjonalnie)" : "Wiadomość do autora (opcjonalnie)"}
                </p>
                <Input
                  name="message"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}
                  isDarkMode={isDarkMode}
                />
                <p className={bodyLargeStrong()}>Twój adres e-mail</p>
                <Input
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  isDarkMode={isDarkMode}
                />
                <Error>{errors.email && touched.email && errors.email}</Error>
                <CheckboxWrapper>
                  <Input
                    type="checkbox"
                    className={bodyLarge()}
                    name="privacyPolicy"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.privacyPolicy}
                  />
                  <span>
                    Akceptuje&nbsp;
                    <Link to="/info/regulamin">regulamin</Link> i{" "}
                    <Link to="/info/polityka-prywatnosci">
                      politykę prywatności
                    </Link>
                  </span>
                </CheckboxWrapper>
                <Error>
                  {errors.privacyPolicy &&
                    touched.privacyPolicy &&
                    errors.privacyPolicy}
                </Error>
                <PayButton type="submit" className={bodyLarge()}>
                  Zostaw napiwek przez Przelewy24
                </PayButton>
              </form>
            </TipColumn>
          </>
        )}
      </Formik>
    </Wrapper >
  );
};

export default withThemeContext(TipFormBasic);

const Wrapper = styled("div", {
  paddingTop: "78px",
  paddingBottom: "31px",
  paddingLeft: "12px",
  paddingRight: "12px",
  "@bp4": {
    display: "flex",
    justifyContent: "space-between",
    gap: "72px",
    alignItems: "flex-start",
    paddingTop: "78px",
    paddingBottom: "31px",
    paddingLeft: "84px",
    paddingRight: "84px",
  },
  "& .photo-on-left": {
    "& span": {
      color: "$grayscale100",
    }
  }
});

const TextColumn = styled("div", {
  h3: {
    margin: 0,
  },
  p: {
    fontWeight: "400",
    marginBottom: "32px",
    marginTop: "16px",
    maxWidth: "454px",
  },
});

const TipColumn = styled("div", {
  "p:first-of-type": {
    marginTop: "32px",
  },
  "@bp4": {
    "p:first-of-type": {
      marginTop: 0,
    },
    width: "50%",
  },
});

const AmountWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "@bp4": {
    gap: "10px",
  },
});

const AmountButton = styled("button", {
  fontSize: 16,
  whiteSpace: "nowrap",
  lineHeight: "24px",
  letterSpacing: "0px",
  fontWeight: "600",
  padding: "12px 22px",
  border: "2px solid rgba(0, 0, 0, 0.05)",
  color: "$grayscale0",
  background: "$grayscale100",
  variants: {
    selected: {
      true: {
        border: "2px solid #459B2B !important",
        backgroundColor: "rgba(69, 155, 43, 0.1)",
        position: "relative",
        "&:after": {
          content: `url(${checkIcon})`,
          position: "absolute",
          width: "16px",
          height: "16px",
          top: "-2px",
          right: "-2px",
          background: "#459B2B",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    },
    isDarkMode: {
      true: {
        borderColor: "rgba(0, 0, 0, 0.05)",
      },
      false: {
        borderColor: "rgba(255, 255, 255, 0.05)",
      },
    },
  },
});

const Textarea = styled("textarea", {
  border: "1px solid rgba(0, 0, 0, 0.05)",
  background: "none",
  fontWeight: 400,
  fontSize: "16px",
  padding: "12px 14px",
  color: "$grayscale0",
  resize: "none",
  width: "100%",
  height: "100px",
  "&:focus-visible": {
    outline: "1px solid $grayscale10",
  },
  variants: {
    isDarkMode: {
      true: {
        borderColor: "rgba(0, 0, 0, 0.05)",
      },
      false: {
        borderColor: "rgba(255, 255, 255, 0.05)",
      },
    },
  },
});

const Input = styled("input", {
  border: "1px solid rgba(0, 0, 0, 0.05)",
  background: "none",
  fontWeight: 400,
  fontSize: "16px",
  padding: "12px 14px",
  color: "$grayscale0",
  width: "100%",
  "&:focus-visible": {
    outline: "1px solid $grayscale10",
  },
  variants: {
    isDarkMode: {
      true: {
        borderColor: "rgba(0, 0, 0, 0.05)",
      },
      false: {
        borderColor: "rgba(255, 255, 255, 0.05)",
      },
    },
  },
});

const CheckboxWrapper = styled("div", {
  display: "flex",
  alignItems: "flex-start",
  marginTop: "16px",
  gap: 10,
  a: {
    color: "$grayscale0",
  },
  input: {
    width: "auto",
    transform: "translateY(30%)",
  },
});

const PayButton = styled("button", {
  background: defaultColors.natematPrimary,
  color: "$grayscale100",
  textAlign: "center",
  border: "none",
  width: "100%",
  padding: "12px 0",
  marginTop: "16px",
});

const MessageTipWrapper = styled("div", {
  paddingLeft: "16px",
  paddingRight: "16px",
  textAlign: "center",
  maxWidth: "800px",
});

const Error = styled("span", {
  color: "$aszdziennikPrimary",
  margin: "0 0 10px 0",
  display: "block",
});

const SuccessTipAuthorWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem",
  textAlign: "left",
  h5: {
    margin: 0,
    lineHeight: 1.5,
  },
  p: {
    margin: 0,
  },
});

const SuccessTipSeeAlsoWrapper = styled("div", {
  flexDirection: "column",
  display: "flex",
  span: {
    color: "$grayscale0",
    textAlign: "left",
  },
  "@bp4": {
    flexDirection: "row",
  },
});

const SuccessTipSeeAlsoHeader = styled("div", {
  display: "flex",
  margin: "40px 0 20px 0",
  alignItems: "center",
  "& p": {
    flexShrink: 0,
    margin: "0 16px 0 0",
    color: "$grayscale0",
  },
});

const SuccessTipSeeAlsoBlackLine = styled("div", {
  borderTop: "2px solid $grayscale90",
  width: "100%",
});
