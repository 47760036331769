import * as React from "react"

import LegacyAdLibEvent from "./Adslots/AdLibEvent"
import AdContainer from "../prebid/AdContainer"

type FeatureFlagAdContainerProps = {
    name: string;
}

const FeatureFlagAdContainer: React.FC<FeatureFlagAdContainerProps> = ({ name }) => {
    return <AdContainer id={name} />
}

export default FeatureFlagAdContainer