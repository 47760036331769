import React from "react"
import { styled } from "../../../stitches.config";
import PhotoOnTop from "../../common/PhotoOnTop"
import TextOnPicture from "../../../redesign/components/TextOnPicture";

const RelatedArticle = ({ article }) => {
    return <>
        <Title>Czytaj także<span>:</span></Title>
        <TextOnPicture article={article} />
    </>
}

const Title = styled("b",{
    "span":{
        color: "$primary"
    }
});

export default RelatedArticle