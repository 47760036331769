import React from "react";
import { styled } from "../../stitches.config";
import Parts from "./Parts/Parts";

import SlateRenderer from "./Parts/Slate/SlateRenderer";
import {
  ColourModeContext,
  withThemeContext,
} from "../../redesign/components/StitchesThemeProvider";
import Breadcrumbs from "../../redesign/components/Breadcrumbs";

// nie można lazy, bo mignie
import Body from "./Content/Body";
// const Body = React.lazy(() => import(/* webpackChunkName: "components__Article__Content__Body" */"./Content/Body"));

class Content extends React.Component {
  render() {
    const { isDarkMode } = this.props;
    const article = this.props.article;
    const fetchedArticle = this.props.fetchedArticle;
    const slateRendered = ["ARTICLE_SLATE", "ARTICLE_VIDEO", "ARTICLE_PODCAST", "ARTICLE_JOB_OFFER"];

    slateRendered.push("LIVE");

    return (
      <section>
        <Wrapper
          isDarkMode={isDarkMode}
          className={"article-body article-body--" + article.category.type}
          id="article-body"
        >

          <div className="art">
            {slateRendered.includes(article.display_type) ? (
              <SlateRenderer
                parts={article.BodyPart}
                hideAdslots={this.props.hideAdslots}
              />
            ) : (
              <Parts
                article={article}
                parts={article.BodyPart}
                hideAdslots={this.props.hideAdslots}
                fetchedArticle={fetchedArticle}
              />
            )}
          </div>
          <Body hideAdslots={this.props.hideAdslots} />
        </Wrapper>
      </section>
    );
  }
}

const Wrapper = styled("div", {
  "a": {
    textDecoration: "none",
    color: "$primary",
    "&:visited": {
      color: "$primary"
    },
    "&:hover": {
      color: "$primary120"
    }
  },
  "@bp4": {
    marginTop: "10px"
  },
  ".breadcrumbs": {
    marginTop: "50px"
  },
  variants: {
    "isDarkMode": {
      true: {
        ".art": {
          "h1": {
            color: "white"
          },
          color: "#e7e7e7"
        }
      },
      false: {}
    }
  }
});

export default withThemeContext(Content);
