import axios, { AxiosResponse } from "axios";
import { PaymentPayload, PaymentType } from "../redesign/types/Payment";

export async function startPayment(
  payload: PaymentPayload,
  type: PaymentType
): Promise<AxiosResponse<any>> {
  try {
    const res = await axios.post(
      `${window.API_HOST}/api/payment`,
      { ...payload, type: type }
    );
    // return res.data;
    return res.data?.redirect_to && window.open(res.data.redirect_to, "_self");
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw { status: err.response!.status, errors: err.response!.data.errors };
    } else {
      throw new Error("Cannot start payment.");
    }
  }
}
