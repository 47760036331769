import React from 'react';
import { styled } from '../../../../stitches.config';

const NavbarBottomPlaceholder = styled("div",{
    borderTop: "1px solid $grayscale25",
    height: "72px",
    width: "100%",
    background: "$grayscale0"
});

export default NavbarBottomPlaceholder;