import React from "react";
import Media from "../../../components/common/Media";
import { defaultColors, styled } from "../../../stitches.config";
import { h3, bodyLarge } from "../../commonStyles/Fonts";
import { withThemeContext } from "../../components/StitchesThemeProvider";
import { PaymentStatus, PaymentType } from "../../types/Payment";
import { ModalType } from "../config";
import ModalLink from "../ModalLink";

interface Props {
  user: any;
  isDarkMode?: boolean;
  headText: string;
  descriptionText: string;
}

const TipCallToAction: React.FC<Props> = ({
  user,
  isDarkMode,
  headText,
  descriptionText,
}) => {
  return (
    <Wrapper
      style={{
        backgroundColor: isDarkMode
          ? defaultColors.grayscale0
          : defaultColors.grayscale25,
      }}
    >
      <Media
        src={user.img}
        height={300}
        width={300}
        preserveRatio={false}
        aspectRatio={1}
        alt={user.name}
      />
      <TextWrapper>
        <h3 className={h3()}>{headText}</h3>
        <p className={bodyLarge()} style={{ fontWeight: 600 }}>
          {descriptionText}
        </p>
        <ModalLink
          type={ModalType.PAYMENT}
          paymentStatus={PaymentStatus.PENDING}
          paymentType={PaymentType.TIP}
          paymentEditorId={user.OID}
          style={{ display: "block", marginTop: "auto" }}
        >
          <Button>Zostaw napiwek</Button>
        </ModalLink>
      </TextWrapper>
    </Wrapper>
  );
};

const Wrapper = styled("div", {
  backgroundColor: `${defaultColors.grayscale0} !important`,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "100%",
  h3: {
    margin: "0",
  },
  "@bp4": {
    flexDirection: "row",
    height: "300px",
  },
});

const TextWrapper = styled("div", {
  padding: "12px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  h3: {
    color: `${defaultColors.grayscale100} !important`,
  },
  p: {
    color: `${defaultColors.grayscale100} !important`,
  },
  "@bp4": {
    padding: "40px",
  },
});

const Button = styled("button", {
  backgroundColor: defaultColors.natematPrimary,
  color: defaultColors.grayscale0,
  border: "none",
  marginTop: "auto",
  padding: "12px 20px",
  width: "100%",
  fontWeight: 600,
  "@bp4": {
    width: "auto",
  },
});
export default withThemeContext(TipCallToAction);
