import React, { useEffect, useState } from 'react';
import ErrorPage from './ErrorPage';
import Button from '../../atoms/Button';
import { ErrorWrapper } from '../../../components/Error';
import { bodySmall } from '../../commonStyles/Fonts';
import { styled } from '../../../stitches.config';
import { pushDataLayer } from '../../../helpers';
import { useLocation } from 'react-router-dom';

interface IErrorReactPage {
    resetError: () => void;
};

const ErrorReactPage: React.FC<IErrorReactPage> = ({ resetError }) => {

    const location = useLocation();

    const [isInit, setInit] = useState(false);

    useEffect(() => {
        setInit(true);
        document.getElementById('articleContentInSource')?.remove();

        pushDataLayer({
            'event': 'virtualPageview',
            'virtualPageURL': window.location.pathname,
            'virtualPageTitle': 'Wystąpił błąd aplikacji',
            'pageType': 'Błąd aplikacji'
        });
    }, []);

    useEffect(() => {
        if (!isInit)
            return;
        resetError();
    }, [location]);

    return (
        <ErrorWrapper>
            <ErrorPage description={(
                <p>
                    Wystąpił błąd, odśwież stronę aby spróbować ponownie.
                </p>
            )} />
            <UnderErrorWrapper>
                <Button
                    onClick={() => window.location.reload()}
                    text="Odśwież"
                    starPosition="none"
                />
                <p className={bodySmall()}>
                    Jeśli problem ciągle występuje daj znam znać: <a href="mailto:support@natemat.pl">support@natemat.pl</a>
                </p>
            </UnderErrorWrapper>
        </ErrorWrapper>
    );

};

export default ErrorReactPage;

const UnderErrorWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '$grayscale100'
});