import React from "react";
import { styled } from "../../stitches.config";
import { observer, inject } from "mobx-react";

const withConsent = (Component) => {

  @inject("AdsStore")
  @observer
  class withConsent extends React.Component {
    render() {
      const { AdsStore } = this.props;

      // console.log('withConsent', AdsStore.tcDataLoaded);

      return (AdsStore.tcDataLoaded)
        ? <Component {...this.props} />
        : <WrapperNoConsent>
          W tym miejscu miał pojawić się niestandardowy element artykułu lub reklama, ale nie widzisz żadnego z tych elementów, ponieważ nie wyraziłeś zgody. Swoje ustawienia prywatności możesz zmienić
          <a href="/twoja-prywatnosc"> tutaj</a>.
        </WrapperNoConsent>;
    }
  }
  return withConsent;
};

const WrapperNoConsent = styled("div",{
  width: "100%",
  color: "#a5a5a5",
  background: "#e3e3e3",
  padding: "10px 20px"
});

const ConsentConditional = withConsent(({ children }) => {
  // console.log('ConsentConditional', children)
  return children;
})

export { withConsent, ConsentConditional };
