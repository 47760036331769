import { useState, useEffect, useMemo } from "react";
import { config as StitchesConfig } from "../stitches.config";

interface StitchesMediaMap {
    [key: string]: string
};

const StitchesMedia: StitchesMediaMap = StitchesConfig.media;
type StitchesMediaVariant = keyof typeof StitchesMedia;

/**
 * Check css media query.
 * Example usage: const isMobile = useMediaQuery("mobile");
 * @param matchQuery StitchesMediaVariant ex. "mobile","bp4" or custom string ex. (min-width: 700px)
 * @returns Media query matches
 */
const useMediaQuery = (matchQuery: StitchesMediaVariant | string): boolean => {

    const mediaQuery = useMemo(() => {
        let query: any = matchQuery;
        if (StitchesMedia[matchQuery])
            query = StitchesMedia[matchQuery];

        return window.matchMedia(query as string);
    }, []);

    const [matchMediaQuery, setMediaQuery] = useState<boolean>(mediaQuery.matches);

    useEffect(() => {
        const checkMediaMatch = (media: MediaQueryListEvent) => setMediaQuery(media.matches);

        if (typeof mediaQuery.addEventListener === 'function')
            mediaQuery.addEventListener('change', checkMediaMatch);
        else
            mediaQuery.addListener(checkMediaMatch); //Support for ios older than 14

        return () => {
            if (typeof mediaQuery.addEventListener === 'function')
                mediaQuery.removeEventListener('change', checkMediaMatch);
            else
                mediaQuery.removeListener(checkMediaMatch); //Support for ios older than 14
        }
    }, []);

    return matchMediaQuery;
};

export default useMediaQuery;
