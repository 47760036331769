export enum PaymentParams {
  PAYMENT_TYPE = "payment-type",
  PAYMENT_STATUS = "payment-status",
  PAYMENT_EDITOR_ID = "payment-editor-id",
  PAYMENT_BRID_ID = "payment-brid-id",
  PAYMENT_VALUE = "payment-value",
}

export enum PaymentStatus {
  SUCCESS = "success",
  FAILED = "failed",
  CANCELLED = "cancelled",
  PENDING = "pending",
  VALUE = "0"
}

export type PaymentPayload = {
  email: string;
  amount: number;
  termsAndCondition: boolean;
  message?: string;
};

export enum PaymentType {
  TIP = "TIP",
  BRID = "BRID",
  PREMIUM = "PREMIUM",
}
