import React from "react";
import { styled } from "../../../stitches.config";

export default class Html extends React.Component {
  render() {
    const { html, isDarkMode, className } = this.props;

    return (
      <ContainerBizon isDarkMode={isDarkMode}
        css={{ "color": (isDarkMode ? "white" : "#131212") }}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    );
  }
}

const ContainerBizon = styled("div", {
  color: "$grayscale100",
  fontSize: "16px",
  fontWeight: 400,
  letterSpacing: "0.1px",
  lineHeight: '26px',
  fontFamily: "CharisSIL",
  a: {
    color: "rgb(255, 102, 0) !important;",
    "&:hover": {
      textDecoration: "none",
    },
  },
  "@bp4": {
    maxWidth: 840,
    margin: "20px auto",
    fontSize: 21,
    letterSpacing: "0px",
    lineHeight: '32px',
  },
});
