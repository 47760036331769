import React, { Component, Fragment, Suspense } from "react";
import { inject, observer } from "mobx-react";
// import { observable } from 'mobx'
import { withRouter } from 'react-router-class-tools';
import { when, computed } from "mobx";
import { styled, Global } from "../stitches.config";
import { Subscribe } from "react-facebook";
import LazyLoad from "react-lazyload";

import Adslot from "../components/Adslot";
import AdslotWithScrollWrapper from "../components/AdslotWithScrollWrapper";
import TrackEvent from "../components/TrackEvent";

import Header from "../components/Article/Header";
import DataLayer from "../components/DataLayer";

// bizonowe
import NextArticle from "../redesign/components/NextArticle";
import ArticleAuthorBottom from "../redesign/components/ArticleAuthorBottom";
import GoBackToMainPage from "../redesign/components/GoBackToMainPage";

import { pushDataLayer } from "../helpers/pushDataLayer";
import {
  IS_BILLBOARD_1_POD_LEADEM,
  IS_FEATURE_mundial2022,
  IS_FEATURE_adunityPartner,
  IS_TABOOLA,
  IS_FEATURE_TIP,
  IS_FEATURE_enableComments,
} from "./common/constants/constants";
import {
  ColourModeContext,
  withThemeContext,
} from "../redesign/components/StitchesThemeProvider";
import { defaultColors } from "../stitches.config";

import Content from "../components/Article/Content";
import MiddlePart from "../components/Article/MiddlePart";
import TipCallToAction from "../redesign/Modal/payment/TipCallToAction";
import TipWidget from "../redesign/components/TipWidget";

import * as Sentry from "@sentry/react";

const SimilarArticlesWithTaboola = React.lazy(() => import(/* webpackChunkName: "redesign__components__SimilarArticlesWithTaboola" */"../redesign/components/SimilarArticlesWithTaboola"));
const TagsBizon = React.lazy(() => import(/* webpackChunkName: "redesign_Tags" */"../redesign/Tags"));
const SocialsArticle = React.lazy(() => import(/* webpackChunkName: "redesign__components__SocialsArticleh" */"../redesign/components/SocialsArticle"));
const RightColumn = React.lazy(() => import(/* webpackChunkName: "redesign__components__ArticleRightColumn" */"../components/Article/ArticleRightColumn"));
const AdultGate = React.lazy(() => import(/* webpackChunkName: "components__AdultGate" */"../components/AdultGate"));

const ArticleRecomendations = React.lazy(() => import(/* webpackChunkName: "components__Article__Components__ArticleRecomendations" */"../components/Article/Components/ArticleRecomendations"));
const DynamicNextArticle = React.lazy(() => import(/* webpackChunkName: "components__Article__DynamicNextArticle" */"../components/Article/DynamicNextArticle"));
const BridFooter = React.lazy(() => import(/* webpackChunkName: "components__Article__Components__BridFooter" */"../components/Article/Components/BridFooter"));

// bizonowe lazy load
const SimilarArticles = React.lazy(() => import(/* webpackChunkName: "redesign__components__SimilarArticles" */"../redesign/components/SimilarArticles"));
const SimilarPodcasts = React.lazy(() => import(/* webpackChunkName: "redesign__components__SimilarPodcasts" */"../redesign/components/SimilarPodcasts"));
const SeeAlsoVideosBizon = React.lazy(() => import(/* webpackChunkName: "redesign__components__SeeAlsoVideos" */"../redesign/components/SeeAlsoVideos"));

// #4571 football score
const FootballScores = React.lazy(() => import(/* webpackChunkName: "components__FootballScores" */"../components/FootballScores"));

//Fb
const FacebookComments = React.lazy(() => import(/* webpackChunkName: "redesign__components__FacebookComments" */"../redesign/components/FacebookComments"));
const FacebookSubscribe = React.lazy(() => import(/* webpackChunkName: "helpers__FacebookSubscribe" */"../helpers/FacebookSubscribe"));

@inject("ArticleStore", "CategoryStore", "UIStore")
@observer
class Article extends Component {
  constructor(props) {
    super(props);
    when(
      () => this.isLive,
      () => this.disposeInterval()
    );
  }
  liveRefresh = null;

  @computed get isLive() {
    if (this.props.ArticleStore.article.display_type === "LIVE") return true;
    return false;
  }

  disposeInterval = () => {
    clearInterval(this.liveRefresh);
    this.liveRefresh = setInterval(
      () => this.props.ArticleStore.updateBodyPart(),
      15000
    );
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.slug !== this.props.match.params.slug) {
      // this.props.ArticleStore.updateSlug(nextProps.match.params.slug);
      clearInterval(this.liveRefresh);
    }
  }

  render() {
    const { ArticleStore, isDarkMode, theme } = this.props;
    const { loadArticles, loadArticlesData } = ArticleStore;
    const isDesktop = window.innerWidth >= 996;
    const isSemi = ArticleStore.article.semi;
    const isVideo = ArticleStore.article.display_type === "ARTICLE_VIDEO";
    const isPodcast = ArticleStore.article.display_type === "ARTICLE_PODCAST";
    const isLive = ArticleStore.article.display_type === "LIVE";
    const isMaterialPartnera = ArticleStore.article.material_partnera;
    const hasTip = IS_FEATURE_TIP && !ArticleStore.article.diasbleJournalistTip;
    const adSuffix = ((IS_FEATURE_adunityPartner && isMaterialPartnera) ? '_partner' : '');

    const showRightColumn =
      window.innerWidth >= 1310 &&
      !isSemi &&
      (isDesktop || !ArticleStore.article.disableMobileReadMoreArticles);

    if (isVideo) {
      this.props.setForceDarkMode(true);
    }

    let comments = null;
    if (!ArticleStore.article.disableComments && IS_FEATURE_enableComments) {
      comments = (
        <FacebookComments href={ArticleStore.article.fb_url}>
          {fbComments => (
            <CommentsContainer isDarkMode={isDarkMode} isSemi={isSemi}>
              <FacebookSubscribe
                event="comment.create"
                onChange={(commentResponse) => {
                  if (!commentResponse.hasOwnProperty("pluginID")) {
                    pushDataLayer({
                      event: "articleComment",
                      articleUrl: `/${ArticleStore.currentSlug}`,
                      articleTitle: ArticleStore.article.seo_title,
                      articleSection: ArticleStore.article.category.name,
                      articleTag:
                        ArticleStore.fetchedArticle.topics.length > 0
                          ? ArticleStore.fetchedArticle.topics[0].name
                          : "",
                      articleAuthor:
                        ArticleStore.fetchedArticle.DATA_LAYER.articleAuthor,
                      articleCharacterRange:
                        ArticleStore.fetchedArticle.DATA_LAYER
                          .articleCharacterRange,
                    });
                  }
                }}
              />
              {fbComments}
            </CommentsContainer>
          )}
        </FacebookComments>
      );

      // lub komentarze pobierane server-side
      // comments = <Comments commentsData={ArticleStore.comments} />
    }
    // console.log({ loadArticlesData });
    return (
      <Fragment key="article-content-container">
        {isSemi && (
          <Global
            styles={{
              ".app-content": {
                paddingTop: "1px",
                backgroundColor: "rgb(19, 18, 18)"
              }
            }}
          />
        )}
        {ArticleStore.article.adultGate && (
          <Suspense fallback={null}>
            <AdultGate />
          </Suspense>
        )}

        <Adslot name={"belka_sticky" + adSuffix} lazy={false} />
        <Adslot
          name={"ART_Billboard_1" + adSuffix}
          only={IS_BILLBOARD_1_POD_LEADEM ? "desktop" : null} />

        <ArticleWrapper
          isVideo={isVideo}
          isPodcast={isPodcast}
          isLive={isLive}
          isDarkMode={isDarkMode}
          isSemi={isSemi}
        >
          {IS_FEATURE_mundial2022 && !isPodcast && !isVideo && !isSemi && !isLive &&
            <div className="container">
              <FootballScores data={window.APP_SETTINGS.sport_results} />
            </div>
          }
          {!isPodcast && !isVideo && <Header article={ArticleStore.article} />}

          {/* middle part  */}
          {isLive && <MiddlePart article={ArticleStore.article} />}

          <div
            className={
              isLive || isVideo || isPodcast
                ? "container"
                : "container extra-margin-redesign"
            }
          >
            <ArticleRow
              isSemi={isSemi}
              showRightColumn={showRightColumn}
              className={isLive ? "extra-margin-live row" : "row"}
            >
              {/*!isVideo &&
                                !isPodcast ? (
                                <ArticleBizonBackground isDarkMode={isDarkMode | isSemi}>
                                    <div className="dots">
                                        <svg
                                            width="100"
                                            height="350"
                                            viewBox="0 0 104 363"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M207.994 101.582C208.218 121.987 202.281 141.995 190.94 159.054C179.599 176.113 163.369 189.449 144.32 197.36C125.271 205.272 104.267 207.4 83.9874 203.473C63.7083 199.546 45.0735 189.743 30.4609 175.313C15.8483 160.884 5.92034 142.483 1.94372 122.458C-2.0329 102.433 0.122152 81.6922 8.13391 62.8819C16.1457 44.0716 29.6509 28.0446 46.9264 16.846C64.2018 5.64729 84.4643 -0.215365 105.128 0.0060478C132.199 0.66871 157.975 11.5831 177.122 30.4906C196.27 49.398 207.323 74.851 207.994 101.582ZM207.994 360.189C208.218 380.595 202.281 400.603 190.94 417.662C179.599 434.721 163.369 448.057 144.32 455.968C125.271 463.879 104.267 466.007 83.9874 462.081C63.7083 458.154 45.0735 448.35 30.4609 433.921C15.8483 419.491 5.92034 401.09 1.94372 381.065C-2.0329 361.041 0.122152 340.3 8.13391 321.49C16.1457 302.679 29.6509 286.652 46.9264 275.454C64.2018 264.255 84.4643 258.392 105.128 258.614C132.199 259.276 157.975 270.191 177.122 289.098C196.27 308.006 207.323 333.459 207.994 360.189Z" />
                                        </svg>
                                    </div>
                                </ArticleBizonBackground>
                            ) : null*/}
              {hasTip && (
                <TipWidget author={ArticleStore.article.author} />
              )}
              <ArticleColumn
                className={
                  isSemi ? "col-lg-12 column-left" : "col-lg-8 column-left"
                }
              >
                {/* {IS_FEATURE_TIP && (
                  <TipWrapper >
                    <TipCallToAction user={ArticleStore.article.author} />
                  </TipWrapper>
                )} */}
                <Content article={ArticleStore.article} />
              </ArticleColumn>

              {isVideo && !isDesktop ? null : (
                <Sentry.ErrorBoundary fallback={<></>}>
                  {" "}
                  {showRightColumn && (
                    <ArticleColumn className="col-lg-4  article-column-right adslot__post_halfpage_0">
                      <RightColumn
                        articleColumnContainer={this.articleColumnContainer}
                      />
                    </ArticleColumn>
                  )}
                </Sentry.ErrorBoundary>
              )}
            </ArticleRow>
          </div>

          {!isVideo && <AdslotWithScrollWrapper name={"ART_Billboard_2" + adSuffix} />}
          <LazyLoad once={true}>
            <DataLayer
              data={{
                event: "articleRead",
                articleUrl: `/${ArticleStore.currentSlug}`,
                articleTitle: ArticleStore.article.seo_title,
                articleSection: ArticleStore.article.category.name,
                articleTag:
                  ArticleStore.fetchedArticle.topics.length > 0
                    ? ArticleStore.fetchedArticle.topics[0].name
                    : "",
                articleAuthor:
                  ArticleStore.fetchedArticle.DATA_LAYER.articleAuthor,
                articleCharacterRange:
                  ArticleStore.fetchedArticle.DATA_LAYER.articleCharacterRange,
              }}
            />
          </LazyLoad>

          {isPodcast ? null : (
            <Suspense fallback={null}>
              <TagsBizon
                isSemi={isSemi}
                tags={ArticleStore.fetchedArticle.topics}
              />
            </Suspense>
          )}

          <Suspense fallback={null}>
            <SocialsArticle />
          </Suspense>

          <ArticleAuthorBottom
            article={ArticleStore.article}
            partnership={ArticleStore.article.BrandingPartnerInfo}
            isPodcast={isPodcast}
            hasTip={hasTip}
          />

          {/* tutaj można "obciąć" artykuł */}
        </ArticleWrapper>

        <BackgroundWrapper isDarkMode={isDarkMode}>
          {!isVideo && (
            <div className="container">
              {comments}
              <LazyLoad>
                <Suspense fallback={null}>
                  <BridFooter />
                </Suspense>
              </LazyLoad>

              <TrackEvent name="AfterComments" category="ANALYTICS" />
              {ArticleStore.article.disableRecomendedArticles || (
                <Suspense fallback={null}>
                  <ArticleRecomendations />
                </Suspense>
              )}
              <TrackEvent name="AfterPlista" category="ANALYTICS" />
            </div>
          )}
          {!isVideo && !isPodcast ? (
            <Sentry.ErrorBoundary fallback={<></>}>
              <Suspense fallback={null}>
                {/* wstawiam taboole, chowam similarArticles */}
                {IS_TABOOLA ? (
                  <SimilarArticlesWithTaboola ArticleStore={ArticleStore} isDesktop={isDesktop} isSemi={isSemi} />
                ) : (
                  <SimilarArticles articles={ArticleStore.similar_articles} />
                )
                }
              </Suspense>
            </Sentry.ErrorBoundary>
          ) : null}

          {!isVideo && <AdslotWithScrollWrapper name={"ART_Billboard_3" + adSuffix} />}
        </BackgroundWrapper>

        {!isVideo && !isPodcast && loadArticlesData.length > 0 && (
          <Sentry.ErrorBoundary fallback={<></>}>
            <NextArticleWrapperBox isDarkMode={isDarkMode}>
              <NextArticleWrapper isDarkMode={isDarkMode}>
                <div class="container">
                  <p class="header">Czytaj więcej</p>
                  {loadArticlesData &&
                    loadArticlesData.length > 0 &&
                    loadArticlesData.map((item, index) => (
                      <NextArticle
                        key={index}
                        articleData={item}
                        adslotNum={index + 4}
                        dataAnalyticsName={"infinite-scroll-article-" + index}
                      />
                    ))}
                </div>
              </NextArticleWrapper>
            </NextArticleWrapperBox>
          </Sentry.ErrorBoundary>
        )}

        {!isVideo && !isPodcast ? <GoBackToMainPage /> : null}

        <BackgroundWrapper isDarkMode={isDarkMode}>

          {isVideo && !isDesktop ? (
            <Sentry.ErrorBoundary fallback={<></>}>
              <Suspense fallback={null}>
                <SeeAlsoVideosBizon articles={ArticleStore.similar_articles} />
              </Suspense>
            </Sentry.ErrorBoundary>
          ) : null}

          {isPodcast ? (
            <Sentry.ErrorBoundary fallback={<></>}>
              <Suspense fallback={null}>
                <SimilarPodcasts article={ArticleStore.article} />
              </Suspense>
            </Sentry.ErrorBoundary>
          ) : null}
        </BackgroundWrapper>
      </Fragment>
    );
  }
}

export default withRouter(withThemeContext(Article));

export const ArticleWrapper = styled("article", {
  position: "relative",
  backgroundColor: "$grayscale10",
  ".extra-margin-live": {
    marginTop: "14px",
    "@bp4": {
      marginTop: "30px"
    }
  },
  ".container": {
    /*overflow-y: hidden;*/ /* zmiana przez #5702 */
    overflowY: "clip"
  },
  /* zmiany pod semi artykuł */
  ".extra-margin-redesign": {
    "@bp4": {
      marginTop: "-80px"
    }
  },
  ".tags-semi": {
    "@bp5": {
      maxWidth: "840px",
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  ".column-left": {
    "@bp5": {
      width: "864px"
    }
  },
  /* zmiana dla brid footer */
  ".brid-universal-compare": {
    margin: "0px 0px 24px 0px !important"
  },
  variants: {
    "isPodcast": {
      true: {
        paddingBottom: "40px",
        backgroundColor: "#fff",
        "@bp4": {
          paddingBottom: "88px"
        }
      },
      false: {}
    },
    "isVideo": {
      true: {
        "@bp4": {
          paddingBottom: "128px"
        }
      },
      false: {}
    },
    "isSemi": {
      true: {
        /* zmiany pod semi artykuł */
        ".extra-margin-redesign": {
          marginTop: "-80px"
        },
        ".social-semi": {
          "@bp5": {
            marginLeft: "16.7%"
          }
        },
        ".author-bottom-semi": {
          "@bp5": {
            marginLeft: "16.7%"
          }
        }
      },
      false: {}
    }
  },
  compoundVariants: [
    {
      "isPodcast": true,
      "isDarkMode": true,
      css: {
        backgroundColor: "#1B1B1B"
      }
    }
  ]
});

export const NextArticleWrapper = styled("article", {
  margin: "0 auto",
  backgroundColor: "$grayscale0",
  color: "$grayscale100",
  padding: "32px 0px 24px 0px",
  "@bp4": {
    padding: "80px 0px 48px 0px"
  },
  ".container > :last-child": {
    "div": {
      borderWidth: "0px !important"
    }
  },
  ".header": {
    fontSize: "24px",
    lineHeight: "30px",
    fontWeight: 700,
    margin: "0",
    "@bp4": {
      fontSize: "38px",
      lineHeight: "42px"
    }
  }
});

export const NextArticleWrapperBox = styled("article", {
  width: "100%",
  backgroundColor: "$grayscale0",
});

export const BackgroundWrapper = styled("div", {
  width: "100%",
  backgroundColor: "#f5f5f5",
  paddingBottom: "32px",
  "@bp4": {
    paddingBottom: "80px"
  },
  variants: {
    "isDarkMode": {
      true: {
        backgroundColor: "#1B1B1B"
      },
      false: {}
    }
  }
});

export const ArticleBizonBackground = styled("div", {
  backgroundColor: "#fff",
  minHeight: "600px",
  width: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  ".dots": {
    position: "absolute",
    right: 0,
    bottom: 0,
    height: "350px",
    width: "100px",
    display: "none",
    "svg": {
      /* #TODO dodać warunek na zmianę kolorów na szary: #E7E7E7*/
      fill: "$primary"
    },
    "@bp4": {
      display: "block"
    }
  },
  variants: {
    "isDarkMode": {
      true: {
        backgroundColor: "#131212",
        ".dots": {
          "svg": {
            fill: "#2b2a2a"
          }
        }
      },
      false: {}
    }
  }
});

const CommentsContainer = styled("div", {
  margin: "20px 0",
  "iframe": {
    width: "100% !important"
  },
  backgroundColor: "transparent",
  "@bp4": {
    width: "627px",
    paddingBottom: "80px",
    paddingLeft: "12px",
    paddingRight: "12px",
    "iframe": {
      width: "627px !important"
    }
  },
  "@bp5": {
    marginLeft: "0px"
  },
  variants: {
    "isDarkMode": {
      true: {
        backgroundColor: "#8D8D8D"
      },
      false: {}
    },
    "isSemi": {
      true: {
        "@bp5": {
          marginLeft: "calc(50% - 333.5px)"
        }
      },
      false: {}
    }
  }
});

export const ArticleRow = styled("div", {
  variants: {
    "isSemi": {
      true: {
        "@bp5": {
          maxWidth: "864px",
          margin: "0 auto !important"
        }
      },
      false: {}
    },
    "showRightColumn": {
      true: {
        "@bp5": {
          display: "flex"
        }
      },
      false: {}
    }
  }
});

export const ArticleColumn = styled("div", {
  "@bp5": {
    /** ta dziwna konstrukcja jest po to, żeby lewa i prawa kolumna miały te same wysokości, żeby halfpage'e w prawej kolumnie dobrze działały */
    /*
      margin-bottom: -99999px;
      padding-bottom: 99999px;
      position: relative;
    */
  }
});

const TipWrapper = styled("div", {
  marginBottom: "6rem"
});