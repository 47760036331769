import React, { Component, Suspense, useState } from "react";
import { observable, toJS } from "mobx";
import { observer, inject } from "mobx-react";
import { Route, Routes as ReactRoutes, Navigate } from "react-router-dom";
import { withRouter } from 'react-router-class-tools';
import { onLCP, onFID, onCLS } from 'web-vitals';
import { AdvertisingProvider } from "@skolimow/react-advertising"
import { initDB } from 'react-indexed-db';
import * as Sentry from "@sentry/react";

// style globalne
import { globalStyles } from "./stitches.config"
import "./Styles" // nowe

import FacebookProviderWrapper from "./components/FacebookProviderWrapper"
import { authInterceptor } from "./helpers/authInterceptor"
import AuthProvider from "./context/AuthContext"
import AudioBriefProvider from "./context/AudioBriefContext"
import Routes from './Routes';
import { styled } from "./stitches.config";
import StitchesThemeProvider from './redesign/components/StitchesThemeProvider'

import PodcastPlayerContext from './context/PodcastPlayerContext';

import ArticleSwiper from "./redesign/ArticleSwiper";

import ErrorReactPage from "./redesign/pages/error-pages/ErrorReactPage";
import Error from "./components/Error";

//Loaded static each of them have own Suspend
import RedesignNav from "./redesign/components/Navbar/Nav"
import RedesignFooter from "./redesign/components/Footer"
import NavbarBottom from "./redesign/components/Navbar/NavbarBottom"
import PrivateRoute from "./components/PrivateRoute"
import RestrictedRoute from "./components/RestrictedRoute"

import { setYBTargeting } from "./helpers/setYBTargeting"
import { plugins } from './prebid/plugins'
import { sendToAnalytics, sendLCPToGTM } from "./helpers/sendToAnalytics"
import { RELOAD_TIME, DISABLE_PREMIUM_FLAG_ACTIVE, IS_ARTICLE_SWIPER_ENABLE, IS_FEATURE_TIP } from './components/common/constants/constants'

import Article from './pages/Article';
import Modal from "./redesign/Modal/Modal";

import { ZenModalStep } from './redesign/types/ZenModalStep';

const ZenModal = React.lazy(() => import(/* webpackChunkName: "redesign__components__ZenModal" */ './redesign/components/ZenModal'));
const ZenModalWrapper = React.lazy(() => import(/* webpackChunkName: "redesign__components__ZenModalWrapper" */ './redesign/components/ZenModalWrapper'));

const CategoryPage = React.lazy(() => import(/* webpackChunkName: "components__CategoryPage" */ './components/CategoryPage'));
const CategoryTrudat = React.lazy(() => import(/* webpackChunkName: "components__CategoryTrudat" */ './components/CategoryTrudat'));
const CategoryZdrowie = React.lazy(() => import(/* webpackChunkName: "components__CategoryZdrowie" */ './components/CategoryZdrowie'));

const PrivacyPage = React.lazy(() => import(/* webpackChunkName: "pages__PrivacyPage" */ './pages/PrivacyPage'));
const PostsMapPage = React.lazy(() => import(/* webpackChunkName: "redesign__pages__PostsMapPage" */ './redesign/pages/PostsMapPage'));
const PostsMapSubpage = React.lazy(() => import(/* webpackChunkName: "redesign__pages__PostsMapPage" */ './redesign/pages/PostsMapSubpage'));
const TopicPage = React.lazy(() => import(/* webpackChunkName: "components__TopicPage" */ './components/TopicPage'));
const AuthorPage = React.lazy(() => import(/* webpackChunkName: "redesign__pages__AuthorPage" */ './redesign/pages/AuthorPage'));
const AuthorsPage = React.lazy(() => import(/* webpackChunkName: "redesign__pages__AuthorsPage" */'./redesign/pages/AuthorsPage'));
const BlogsPageRedesign = React.lazy(() => import(/* webpackChunkName: "redesign__pages__BlogsPage" */'./redesign/pages/BlogsPage'));
const BlogerPageRedesign = React.lazy(() => import(/* webpackChunkName: "redesign__pages__BlogerPage" */'./redesign/pages/BlogerPage'));
const ForYouPage = React.lazy(() => import(/* webpackChunkName: "redesign__pages__ForYouPage" */'./redesign/pages/ForYouPage'));
const RegistrationForm = React.lazy(() => import(/* webpackChunkName: "redesign__pages__RegistrationForm" */'./redesign/components/RegistrationForm'));
const LoginForm = React.lazy(() => import(/* webpackChunkName: "redesign__pages__LoginForm" */'./redesign/components/LoginForm'));
const ResetPassword = React.lazy(() => import(/* webpackChunkName: "redesign__pages__ResetPassword" */'./redesign/components/ResetPassword'));
const ResetPasswordToken = React.lazy(() => import(/* webpackChunkName: "redesign__pages__ResetPasswordToken" */'./redesign/components/ResetPasswordToken'));
const Dashboard = React.lazy(() => import(/* webpackChunkName: "redesign__pages__Dashboard" */'./redesign/pages/Dashboard'));
const Contact = React.lazy(() => import(/* webpackChunkName: "redesign__pages__Contact" */'./redesign/pages/Contact'));
const ExitScreenPage = React.lazy(() => import(/* webpackChunkName: "redesign__pages__ExitScreenPage" */'./redesign/pages/ExitScreenPage'));

const Careers = React.lazy(() => import(/* webpackChunkName: "redesign__pages__CareerPage" */'./redesign/pages/CareerPage'));
const AuthorTip = React.lazy(() => import(/* webpackChunkName: "redesign__pages__TipPage" */'./redesign/pages/AuthorTipPage'));
const ReaderTip = React.lazy(() => import(/* webpackChunkName: "redesign__pages__NapiwkiPage" */'./redesign/pages/ReaderTipPage'));

const PodcastsPage = React.lazy(() => import(/* webpackChunkName: "redesign__pages__PodcastsPage" */'./redesign/pages/PodcastsPage'));
const PodcastSeriePage = React.lazy(() => import(/* webpackChunkName: "redesign__pages__PodcastsPage" */'./redesign/pages/PodcastSeriePage'));

const SearchPage = React.lazy(() =>/* webpackChunkName: "redesign__pages__SearchPage" */ import('./redesign/pages/SearchPage'));

//const TestPlaygroundPage = React.lazy(() => import(/* webpackChunkName: "redesign__pages__NapiwkiPage" */'./redesign/pages/TestPlaygroundPage'));
const AdsPage = React.lazy(() => import(/* webpackChunkName: "redesign__pages__AdsPage" */"./pages/AdsPage"));
const InfoPage = React.lazy(() => import(/* webpackChunkName: "redesign__pages__InfoPage" */"./pages/InfoPage"));

const CommercialBreakOverlay = React.lazy(() => import(/* webpackChunkName: "components__CommercialBreakOverlay" */"./components/CommercialBreakOverlay"));

export const DBConfig = {
  name: 'UserAnalytics',
  version: 2,
  objectStoresMeta: [
    {
      store: 'visit',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'articleId', keypath: 'articleId', options: { unique: false } },
        { name: 'categoryId', keypath: 'categoryId', options: { unique: false } },
        { name: 'datetime', keypath: 'datetime', options: { unique: false } },
      ]
    },
    {
      store: 'visitedCategories',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'categoryId', keypath: 'categoryId', options: { unique: true } },
        { name: 'count', keypath: 'count', options: { unique: false } },
      ]
    },
    {
      store: 'notifications',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'visitedId', keypath: 'visitedId', options: { unique: true } },
      ]
    },
  ]
};

initDB(DBConfig);

const CalculatorPage = React.lazy(() => import('./pages/FertilityCalcPage'))

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

const initAdform = () => {
  var ga = document.createElement("script");
  ga.type = "text/javascript";
  ga.async = true;
  ga.defer = true;
  ga.src =
    ("https:" === document.location.protocol ? "https://" : "http://") +
    "s1.adform.net/banners/scripts/adx.js";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(ga, s);
};
if (!window.TESTING_MODE) {
  initAdform();
}

// const iframe = document.querySelector('.adslot__ad-container[data-name*=_belka_top] iframe')
// const belkaTopExpandButton = iframe.contentWindow.document.getElementById("expand-button");
// if (typeof (belkaTopExpandButton) != 'undefined' && belkaTopExpandButton != null) {
//   belkaTopExpandButton.addEventListener("click", event => {
//     console.log("event KLIKAM")
//     if (this.innerHTML === "ROZWIŃ") {
//       console.log("event ROZWIŃ")
//     } else {
//       console.log("event ZWIŃ")
//     }
//   })
// }


/**
 * wyświetlenie prośby o instalację
 * 
 * @param e
 * @returns
 */
window.addEventListener("beforeinstallprompt", function (e) {
  // window.installPromptEvent = e;
  window._gaq.push(["_trackEvent", "PWAHomeIcon", "PWAHomeIconPromptBefore"]);

  e.userChoice.then(function (res) {
    if (res.outcome === "dismissed") {
      window._gaq.push(["_trackEvent", "PWAHomeIcon", "PWAHomeIconCancelled"]);
    } else if (res.outcome === "default") {
      window._gaq.push(["_trackEvent", "PWAHomeIcon", "PWAHomeIconDismissed"]);
    } else {
      window._gaq.push(["_trackEvent", "PWAHomeIcon", "PWAHomeIconAdded"]);
    }
  });
});
window.addEventListener('appinstalled', (evt) => {
  window._gaq.push(["_trackEvent", 'PWAHomeIcon', "PWAHomeIconInstalled"]);
});
if (window.matchMedia('(display-mode: standalone)').matches) {
  window._gaq.push(["_trackEvent", 'PWAHomeIcon', "PWARunApp"]);
}

authInterceptor();

@inject("ArticleStore", "UIStore", "AdsStore")
@observer
class App extends Component {

  @observable
  timestamp = null
  isZenPaymentSuccess

  updateHistory() {
    const { location, UIStore } = this.props;
    UIStore.updateHistory(location);
  }

  handleVisibilityChange = () => {
    // #5100 PILNE - Zmiany na stronach
    // tymczasowo wyłączmy
    return;

    if (document[hidden]) {
      this.timestamp = Date.now();
    } else {
      let timeNow = Date.now();
      const timeDiff = timeNow - this.timestamp;
      // console.log(timeDiff)
      if (timeDiff > RELOAD_TIME) {
        window.scrollTo(0, 0);
        window.location.reload();
      }
    }
  }

  handleGoogletagCorrelatorUpdate = () => {
    const { googletag } = window;
    googletag.cmd.push(() => {
      googletag.pubads().clearTargeting();
      if (typeof googletag.pubads().updateCorrelator == 'function') {
        googletag.pubads().updateCorrelator();
      }

      for (let i = 0; i < googletag.pubads().getSlots().length; i++) {
        googletag.pubads().getSlots()[i].clearTargeting();
      }
    })
  }

  componentDidMount() {
    const { UIStore } = this.props;
    window.addEventListener('pageLoaded', this.handleGoogletagCorrelatorUpdate)
    window.APP_LOADED = true;
    if (document[hidden]) {
      this.timestamp = Date.now();
    }

    onCLS(sendToAnalytics, true);
    // onFID(sendToAnalytics);
    // onLCP(sendToAnalytics);
    onLCP(sendLCPToGTM);


    UIStore.isDesktop && document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
    setYBTargeting();

    window._gaq.push(["_trackEvent", 'PWA', "appComponent-componentDidMount"]);

    // document.getElementById("articleContentInSource")?.remove();
    document.getElementById("staticsplashscreen")?.remove();
    this.updateHistory();
  }

  componentDidCatch(error, info) {
    window._gaq.push(["_trackEvent", 'PWA', "appComponent-componentDidCatch", error.message]);
    fetch(window.API_HOST + "/pwa-error/", {
      method: "POST",
      body: JSON.stringify({
        context: 'appComponent-componentDidCatch',
        error: error
      })
    })
    // window.APP_FORCE_RWD();
  }

  componentWillUnmount() {
    document.removeEventListener(visibilityChange, this.handleVisibilityChange);
  }

  /**
   * inaczej niż w dokumentacji, ale w dokumentacji porównuje obiekt z obiektem, i tam hash się zmienia
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    const oldLocation = prevProps.location;
    const newLocation = this.props.location;
    const oldLocationString = oldLocation.pathname + oldLocation.search + oldLocation.hash;
    const newLocationString = newLocation.pathname + newLocation.search + newLocation.hash;
    if (oldLocationString !== newLocationString) {
      this.onRouteChange();
      this.updateHistory();
    }
  }

  onRouteChange = () => {
    const event = new CustomEvent("pageLoaded")
    // setTimeout(() => {
    window.dispatchEvent(event)
    setYBTargeting();
    // }, 200);
  }

  componentWillUnmount() {
    window.removeEventListener("pageLoaded", this.handleGoogletagCorrelatorUpdate)
  }

  render() {
    const {
      updateServiceWorkerStatus,
      serviceWorkerStatus,
      showLoginModal,
      nav
    } = this.props.UIStore;

    const { AdsStore, location, ArticleStore } = this.props
    // AdsStore.tcDataLoaded && AdsStore.isAdConfigLoaded && console.log(toJS(AdsStore.prebidConfig))
    const background = location.state && location.state.background;
    return (
      <StitchesThemeProvider>
        {globalStyles()}

          <Main showNavSpace={nav == 'MAIN' || nav === 'AUDIO'}>
            <AdvertisingProvider config={AdsStore.tcDataLoaded && AdsStore.isAdConfigLoaded ? toJS(AdsStore.prebidConfig) : undefined} plugins={plugins} active={true}>
              <FacebookProviderWrapper>
                <AuthProvider>
                  <Suspense fallback={null}>
                    <CommercialBreakOverlay />
                  </Suspense>
                  <AudioBriefProvider>
                    <RedesignNav />
                    <Sentry.ErrorBoundary fallback={({ resetError }) => <ErrorReactPage resetError={resetError} />}>
                      <div className="app-content">
                        {window.innerWidth < 996 && <NavbarBottom />}
                        <PodcastPlayerContext>
                          <Modal />
                          <Suspense fallback={null}>
                            <ReactRoutes location={background || location} basename={window.API_HOST}>
                              <Route path="*/story" element={<Navigate to="/" />} />
                              <Route exact path={Routes.home} element={<CategoryPage />} />
                              <Route path={Routes.categoryTrudat} element={<CategoryTrudat />} />
                              <Route exact path={Routes.categoryZdrowie} element={<CategoryZdrowie />} />
                              <Route path={Routes.zdrowieArticle} element={<Article />} />
                              <Route path={Routes.category} element={<CategoryPage />} />
                              <Route path={Routes.blogerArticle} element={<Article />} />
                              <Route path={Routes.blogerHome} element={<BlogerPageRedesign />} />
                              <Route path={Routes.blogs} element={<BlogsPageRedesign />} />
                              <Route path={Routes.forYou} element={<ForYouPage />} />
                              <Route path={Routes.topic} element={<TopicPage />} />
                              <Route path={Routes.redakcja} element={<AuthorsPage />} />
                              <Route path={Routes.user} element={<AuthorPage />} />
                              {window.BRAND === "mamadu" && <Route path={Routes.fertilityCalcPage} element={<CalculatorPage />} />}
                              <Route path={Routes.info} element={<InfoPage />} />
                              {!DISABLE_PREMIUM_FLAG_ACTIVE && <Route path={Routes.login} element={<RestrictedRoute element={<LoginForm />} />} />}
                              {!DISABLE_PREMIUM_FLAG_ACTIVE && <Route path={Routes.register} element={<RestrictedRoute element={<RegistrationForm />} />} />}
                              {!DISABLE_PREMIUM_FLAG_ACTIVE && <Route path={Routes.dashboard} element={<PrivateRoute element={<Dashboard />} />} />}
                              {!DISABLE_PREMIUM_FLAG_ACTIVE && <Route path={Routes.resetPassword} element={<ResetPassword />} />}
                              {!DISABLE_PREMIUM_FLAG_ACTIVE && <Route path={Routes.resetPasswordToken} element={<ResetPasswordToken />} />}
                              <Route path={Routes.contact} element={<Contact />} />
                              <Route path={Routes.career} element={<Careers />} />
                              <Route path={Routes.jobOfferArticle} element={<Article />} />
                              {IS_FEATURE_TIP && <Route path={Routes.authortip} element={<AuthorTip />} />}
                              {IS_FEATURE_TIP && <Route path={Routes.readertip} element={<ReaderTip />} />}
                              <Route path={Routes.adTests} element={<AdsPage />} />
                              <Route path={Routes.search} element={<SearchPage />} />
                              <Route path={Routes.postsmapsubpage} element={<PostsMapSubpage />} />
                              <Route exact path={Routes.postsmap} element={<PostsMapPage />} />
                              <Route path={Routes.privacy} element={<PrivacyPage />} />
                              <Route path={Routes.podcastSerie} element={<PodcastSeriePage />} />
                              <Route path={Routes.podcasts} element={<PodcastsPage />} />
                              <Route path={Routes.exitScreen} element={<ExitScreenPage />} />
                              <Route exact path={Routes.article} element={(
                                <>
                                  <Article />
                                  {IS_ARTICLE_SWIPER_ENABLE && <ArticleSwiper />}
                                </>
                              )} />
                              <Route element={<Error />} />
                            {/* <Route exact path={Routes.testPlayground} element={<TestPlaygroundPage />} /> */}
                            </ReactRoutes>
                            <ReactRoutes>
                              <Route path="/:name/zen-mode-payment-success" element={<ZenModal step={ZenModalStep.SUCCESS} />} />
                            </ReactRoutes>
                          </Suspense>
                        </PodcastPlayerContext>
                      </div>
                    </Sentry.ErrorBoundary>
                  </AudioBriefProvider>
                  <Suspense fallback={null}>
                    {showLoginModal && <ZenModalWrapper />}
                  </Suspense>
                </AuthProvider>
              </FacebookProviderWrapper>
              <RedesignFooter />

              {
                serviceWorkerStatus === "waiting" && (
                  <ServiceWorkerMessage>
                    <div className="info"> Nowa wersja aplikacji dostępna :) </div>
                    <div
                      className="refresh"
                      onClick={() => updateServiceWorkerStatus({ action: "refresh" })}
                    >
                      {" "}
                      Aktualizuj{" "}
                    </div>
                  </ServiceWorkerMessage>
                )
              }
            </AdvertisingProvider>
          </Main>
      </StitchesThemeProvider>
    );
  }
}

const Main = styled("main",{
  ".hp__block--four, .hp__block--three, .hp__block--two": {
    ".hp__box--column:hover": { 
      ".hp__box__title": { 
        color: "$primary" 
      } 
    }
  },
  ".hp__box--main, .hp__box--small__content": {
    "&:hover": {
      ".hp__box__social, .hp__box__title": { 
        color: "$primary" 
      }
    }
  },
  ".hp__box--column--blog": {
    ".hp__box__blog:hover": { 
      ".hp__box__label": { 
        color: "$primary" 
      } 
    }
  }
});

const ServiceWorkerMessage = styled("div",{
  position: "fixed",
  bottom: "0",
  left: "0",
  background: "$primary",
  width: "100vw",
  alignItems: "center",
  padding: "15px",
  color: "#fff",
  fontWeight: 500,
  display: "flex",
  zIndex: 20,
  boxShadow: "0px -4px 6px 0 rgba(0, 0, 0, 0.3)",
  ".refresh": { 
    cursor: "pointer", 
    marginLeft: "auto", 
    fontWeight: "bold" 
  }
});

export default withRouter(App);