import React, { useInsertionEffect } from 'react';
import { createStitches } from '@stitches/react';
import * as Stitches from '@stitches/react';

export type BrandColors = {
    [type in Config.Brand]: {
        primary120: string;
        primary: string;
        primary60: string;
    }
}

//https://codepen.io/sosuke/pen/Pjoqqp
type BrandSVGFilter = {
    [type in Config.Brand]: {
        svgPrimary: string;
    }
}

export const mainColor: BrandColors = {
    natemat: {
        primary120: "#E45A00",
        primary: "#FF6400",
        primary60: "#FFEADC"
    },
    aszdziennik: {
        primary120: "#B82D30",
        primary: "#EA3A3E",
        primary60: "#FBD8D8"
    },
    mamadu: {
        primary120: "#55B6B7",
        primary: "#62CACB",
        primary60: "#DFF4F5"
    },
    innpoland: {
        primary120: "#3F842A",
        primary: "#459B2B",
        primary60: "#DAEBD5"
    },
    dadhero: {
        primary120: "#2C63CD",
        primary: "#3376F6",
        primary60: "#D6E4FD"
    }
}

export const mainSVGFilter: BrandSVGFilter = {
    natemat: {
        svgPrimary: "invert(38%) sepia(70%) saturate(3051%) hue-rotate(6deg) brightness(109%) contrast(102%)"
    },
    aszdziennik: {
        svgPrimary: "invert(33%) sepia(71%) saturate(5483%) hue-rotate(342deg) brightness(98%) contrast(87%)"
    },
    mamadu: {
        svgPrimary: "invert(100%) sepia(48%) saturate(5786%) hue-rotate(153deg) brightness(84%) contrast(89%)"
    },
    innpoland: {
        svgPrimary: "invert(45%) sepia(82%) saturate(415%) hue-rotate(61deg) brightness(96%) contrast(91%)"
    },
    dadhero: {
        svgPrimary: "invert(40%) sepia(85%) saturate(2987%) hue-rotate(208deg) brightness(98%) contrast(98%)"
    }
}

const getPrimaryColors = (brand: Config.Brand): BrandColors[keyof BrandColors] => (mainColor[brand]);

const getSVGFilter = (brand: Config.Brand): BrandSVGFilter[keyof BrandSVGFilter] => (mainSVGFilter[brand]);

export const defaultColors = {
    ...getPrimaryColors(window.BRAND),
    natematPrimary: "#FF6400",
    aszdziennikPrimary: "#EA3A3E",
    innpolandPrimary: "#459B2B",
    mamaduPrimary: "#62CACB",
    dadheroPrimary: "#3376F6",
    darkmodeStroke: "#3A3A3A",
    darkmodeBg: "#1B1B1B",
    grayscale0: "#FFFFFF",
    grayscale10: "#F5F5F5",
    grayscale25: "#E7E7E7",
    grayscale50: "#CCCCCC",
    grayscale75: "#8D8D8D",
    grayscale90: "#1B1B1B",
    grayscale100: "#131212",
    grayscale100Fixed: "#131212",
    grayscale0Fixed: "#FFFFFF",
    columnistBackground: "#FFFFFF",
    hardcoverColor: "#f72c3f"
}

export const { styled, css, globalCss, config, theme, createTheme, keyframes } = createStitches({
    media: {
        mobile: "(max-width: 995px)",
        desktop: "(min-width: 996px)",
        bp6: "(min-width: 1310px)",
        bp5: "(min-width: 1310px)",
        bp4: "(min-width: 996px)",
        bp3: "(min-width: 768px)",
        bp2: "(min-width: 375px)",
        bp1: "(min-width: 360px)",
    },
    theme: {
        colors: {
            ...defaultColors
        },
        filter: {
            ...getSVGFilter(window.BRAND)
        },
        fonts: {
            grotesk: "HK Grotesk, sans-serif",
            charis: "CharisSIL, serif"
        },
        fontWeights: {
            4: 400,
            6: 600,
            7: 700,
            8: 800
        },
        fontSize: {},
        lineHeights: {
            16: "16px",
            18: "18px",
            24: "24px",
            26: "26px",
            30: "30px",
            32: "32px",
            40: "40px",
            62: "62px"

        },
        letterSpacings: {
            0: 0,
            1: 0.1,
            2: 0.2,
            3: 0.3,
            8: 0.8
        }
    },
});

export const darkTheme = createTheme({
    colors: {
        grayscale0: "#131212",
        grayscale10: "#1B1B1B",
        grayscale25: "#8D8D8D",
        grayscale50: "#CCCCCC",
        grayscale75: "#E7E7E7",
        grayscale90: "#8D8D8D",
        grayscale100: "#FFFFFF",
        columnistBackground: "#232323",
    },
});

export const globalStyles = globalCss({
    "*": {
        boxSizing: "border-box",
        ".container": {
            "@bp2": {
                // border: "1px solid blue",
                width: 375,
            },
        }
    },
    "body": {
        backgroundColor: "$grayscale0",
    },
});

export type CSS = Stitches.CSS<typeof config>;

export const useGlobalCSS = (styles: any, deps?: React.DependencyList) => {

    useInsertionEffect(() => {
        const rootElement = document.documentElement;
        const additionalStyles = css(styles).toString();

        rootElement.classList.add(additionalStyles);

        return () => rootElement.classList.remove(additionalStyles);
    }, deps ?? []);

};

export const Global: React.FC<{ styles: any }> = ({ styles }) => {
    useGlobalCSS(styles);
    return null;
};