import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { startPayment } from "../../../api/payment";
import { styled } from "../../../stitches.config";
import { withThemeContext } from "../../components/StitchesThemeProvider";
import { PaymentStatus, PaymentType } from "../../types/Payment";
import TipFormBasic from "./TipFormBasic";

interface Props {
  colorMode?: string;
  dark?: boolean;
  descriptionText: string;
  allAuthors: string[];
}

const TipFormStandalone: React.FC<Props> = ({
  colorMode,
  allAuthors,
  dark,
  descriptionText
}) => {
  const isDarkMode = dark || colorMode === "dark";
  const location = useLocation();
  const navigate = useNavigate();
  const isManyAuthors = (allAuthors && allAuthors.length > 1);
  const userOidJoined = allAuthors.join(",");

  const getManyAuthorsParams = (status: string) => {
    return `modal-type=PAYMENT&payment-type=TIP&payment-editor-id=${userOidJoined}&payment-status=${status}`;
  };

  const onClick = (amount: number, email: string, message?: string) => {
    const payload = {
      amount: amount,
      termsAndCondition: true,
      message: message,
      email: email,
      User_OID: userOidJoined,
    };
    startPayment(payload, PaymentType.TIP)
      .then(() => {
        navigate(
          `${location.pathname}?${getManyAuthorsParams(
            PaymentStatus.SUCCESS
          )}`
        );
      })
      .catch(() => {
        navigate(
          `${location.pathname}?${getManyAuthorsParams(
            PaymentStatus.FAILED
          )}`
        );
      });

    //to jest mock zapytania do api
  };
  return (
    <TipWrapper isDarkMode={isDarkMode}>
      <TipFormBasic
        onPayButtonClick={onClick}
        descriptionText={descriptionText}
        isManyAuthors={isManyAuthors}
      />
    </TipWrapper>
  );
};

export default withThemeContext(TipFormStandalone);

const TipWrapper = styled("div", {
  background: "$grayscale100",
  marginTop: "32px",
  position: "relative",
  color: "$grayscale0 !important",
  "a, input, textarea": {
    color: "$grayscale0 !important",
  },
  a: {
    color: "$natematPrimary !important",
  },
  "button[type=submit]": {
    backgroundColor: "$natematPrimary !important",
    color: "$grayscale0 !important",
  },
  "& > div": {
    padding: "40px 20px",
  },
  variants: {
    isDarkMode: {
      true: {
        "input,textarea": {
          borderColor: "rgba(0, 0, 0, 0.05)",
        },
      },
      false: {
        "input,textarea": {
          borderColor: "rgba(255, 255, 255, 0.05)",
        },
      },
    },
  },
});
