import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { pushDataLayer } from './pushDataLayer';
import { useStores } from './useStores';

export const useDeepViewChecker = () => {

    let { current: maxScroll } = useRef<number>(window.scrollY);
    let { current: steps } = useRef<number[]>([]);
    const location = useLocation();
    const { pathname, search } = location;

    const { UIStore } = useStores();
    const { numberOfUserInteractions } = UIStore;

    useEffect(() => {
        //Reset maxScroll
        maxScroll = window.scrollY;
        steps = [200, 400, 600, 800, 1000];

        const handleScroll = () => {
            const stepSize = 200;

            const isExitScreen = new URLSearchParams(search).get('__exsc') != null;

            if (window.scrollY - maxScroll >= stepSize && window.scrollY > maxScroll) {
                maxScroll = window.scrollY;
                const step = (maxScroll / stepSize >> 0) * stepSize;

                const passedSteps = steps.filter(s => s <= step);
                if (passedSteps.length == 0)
                    return;

                steps = steps.filter(s => s > step);

                const interactions = isExitScreen ? 'exit' : numberOfUserInteractions;
                //console.log('[ES] scroll', passedSteps, interactions, pathname);

                passedSteps.forEach(s => {
                    window._gaq.push(["_trackEvent",
                        "User_article_read",
                        `${pathname} ${interactions}`,
                        `${s}`
                    ]);

                    pushDataLayer({
                        event: "analyticsEvent",
                        eventCategory: "User_article_read",
                        eventLabel: `${pathname} ${interactions}`,
                        eventAction: `${s}`,
                        eventValue: undefined
                    });
                })
            }
        };

        window.addEventListener('scroll', handleScroll, {
            passive: true
        });
        return () => window.removeEventListener('scroll', handleScroll);
    }, [location]);

};

export const DeepViewChecker: React.FC = () => {
    useDeepViewChecker();
    return null;
};
