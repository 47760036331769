import { Formik } from "formik";
import React, { useEffect } from "react";
import Link from "../../../components/Link";
import useModal from "../../../helpers/useModal";
import { defaultColors, styled } from "../../../stitches.config";
import {
  bodyLarge,
  bodyLargeStrong,
  bodyStrong,
  h3,
} from "../../commonStyles/Fonts";
import CustomLink from "../../Link";
import { PaymentStatus } from "../../types/Payment";
import * as Yup from "yup";
import { withThemeContext } from "../../components/StitchesThemeProvider";
import checkIcon from "../../../../src/assets/img/white_check.svg";
import Avatar from "../../atoms/Avatar";
import PhotoOnLeft from "../../components/PhotoOnLeft";
import axios from "axios";

import placeholder from "../../../../src/assets/img/natemat/ad_placeholder.png";

interface Props {
  onPayButtonClick: (amount: number, email: string, message?: string) => void;
  colorMode?: string;
}

const defaultAmounts = [
  {
    amountText: "2 zł",
    amountValue: 2,
  },
  {
    amountText: "5 zł",
    amountValue: 5,
  },
  {
    amountText: "10 zł",
    amountValue: 10,
  },
  {
    amountText: "Inne",
  },
];

export const SuccessTipModal = ({ authorId }: { authorId: string }) => {
  const [allAuthorsDetails, setAllAuthorsDetails] = React.useState<any>(null);

  const isAllAuthors = Array.isArray(authorId);

  async function fetchAllAuthorsData(oids: number[]) {
    try {
      const requests = oids.map((id) =>
        axios.get(`${window.API_HOST}/pwa-user/${id}`)
      );

      const responses = await Promise.all(requests);

      const authorsDetails = responses.map((response) => response.data.user);
      setAllAuthorsDetails(authorsDetails);
    } catch (error) {
      console.error("Wystąpił błąd podczas pobierania danych:", error);
    }
  }

  useEffect(() => {
    const ids = authorId.split(",").map((id) => parseInt(id));
    fetchAllAuthorsData(ids);
  }, [authorId]);

  if (!allAuthorsDetails) return <LoadingText className={h3()}>Ładowanie</LoadingText >;
  return (
    <MessageTipWrapper>
      {allAuthorsDetails.length > 1 ?
        <h3 className={h3()}>
          Dzięki za napiwek! 🙏 <br />  Cieszymy się, że podoba Ci się nasza praca i ją doceniasz.
        </h3>
        :
        <h3 className={h3()}>
          Dzięki za napiwek! 🙏 <br /> Cieszę się, że podoba Ci się moja praca i
          ją doceniasz.
        </h3>
      }
      <SuccessTipAuthorWrapper>
        {allAuthorsDetails.map((authorDetails: any, i: number) => (
          <React.Fragment key={i}>
            <Avatar
              src={authorDetails?.img}
              url={authorDetails?.url || ""}
              border
              css={{ width: "100%", height: "100%" }}
            />
            <div>
              <h5>{authorDetails?.name}</h5>
              <p>{authorDetails?.title}</p>
            </div>
          </React.Fragment>
        ))}
      </SuccessTipAuthorWrapper>
      {allAuthorsDetails[0].last_posts && (
        <>
          <SuccessTipSeeAlsoHeader>
            <p className={bodyStrong()}>
              Zobacz inne {isAllAuthors ? "nasze" : "moje"} artykuły
            </p>
            <SuccessTipSeeAlsoBlackLine></SuccessTipSeeAlsoBlackLine>
          </SuccessTipSeeAlsoHeader>
          <SuccessTipSeeAlsoWrapper>
            {allAuthorsDetails[0]?.last_posts
              .slice(0, 2)
              .map((article: any, i: number) => (
                <PhotoOnLeft
                  key={"similar_articles__" + i}
                  article={article}
                  boxSize="xxs"
                  dataAnalyticsName={"tip-modal-" + i}
                />
              ))}
          </SuccessTipSeeAlsoWrapper>
        </>
      )}
    </MessageTipWrapper>
  );
};

const FailedTipModal = () => {
  return (
    <MessageTipWrapper>
      <h3 className={h3()}>Coś poszło nie tak</h3>
    </MessageTipWrapper>
  );
};

type TipFromValues = {
  email: string;
  message: string;
  amount: number;
  privacyPolicy: boolean;
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Pole wymagane")
    .email("Wprowadź poprawny adres e-mail"),
  amount: Yup.number().required("Pole wymagane").min(1, "Pole wymagane"),
  message: Yup.string(),
  privacyPolicy: Yup.boolean()
    .required("Musisz zaakceptować warunki")
    .oneOf([true], "Musisz zaakceptować warunki."),
});

const TipModal: React.FC<Props> = ({ onPayButtonClick, colorMode }) => {
  const { modalData } = useModal();
  const [selectedAmountText, setSelectedAmountText] = React.useState<string>(
    defaultAmounts[1].amountText
  );
  const [authorsDetails, setAuthorsDetails] = React.useState<any>(null);

  useEffect(() => {
    if (modalData?.paymentEditorId) {
      const paymentEditorIds = modalData?.paymentEditorId.split(",");
      const requests = paymentEditorIds.map((id) =>
        axios.get(`${window.API_HOST}/pwa-user/${id}`)
      );

      Promise.all(requests).then((responses) => {
        const authorsDetails = responses.map((response) => response.data.user);
        console.log(authorsDetails);
        setAuthorsDetails(authorsDetails);
      });
    }
  }, [modalData?.paymentEditorId]);

  const initialValues: TipFromValues = {
    email: "",
    message: "",
    amount: 5,
    privacyPolicy: false,
  };
  const isDarkMode = colorMode === "dark";

  const isOtherValue = selectedAmountText === "Inne";
  switch (modalData?.paymentStatus) {
    case PaymentStatus.SUCCESS:
      if (modalData?.paymentEditorId)
        return (
          <Wrapper>
            <SuccessTipModal authorId={modalData?.paymentEditorId} />
          </Wrapper>
        );
      break;
    case PaymentStatus.FAILED:
      return (
        <Wrapper>
          <FailedTipModal />
        </Wrapper>
      );
      break;
  }

  console.log(authorsDetails);

  const onSubmit = ({
    email,
    amount,
    message,
    privacyPolicy,
  }: TipFromValues) => {
    onPayButtonClick(amount, email, message);
  };

  const isManyAuthors = authorsDetails?.length > 1;
  return (
    <Wrapper>
      <ImagesWrapper numberOfImages={authorsDetails?.length || 1}>
        {authorsDetails?.map((authorDetails: any, i: number) => (
          <img
            src={authorDetails?.img || placeholder}
            alt=""
            key={authorDetails.OID}
          />
        ))}
      </ImagesWrapper>
      <TipColumn>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
            handleSubmit,
          }) => (
            <>
              <h3 className={h3()}>
                Spodobał Ci się artykuł?
                <br />
                Zostaw {isManyAuthors ? "autorom" : "autorowi"} mały napiwek.
              </h3>
              <p className={bodyLarge()} style={{ fontWeight: 600 }}>
                Cała kwota trafi do {isManyAuthors ? "autorów" : "autora"}. Wraz
                z napiwkiem możesz przekazać też krótką wiadomość.
              </p>
              <CustomLink
                text="Sprawdź, jak to działa"
                url="/439801,natemat-docenione-przez-google-stworzymy-system-napiwkow-dla-dziennikarzy"
                size="monochromatic"
              />
              <form onSubmit={handleSubmit}>
                <p className={bodyLargeStrong()} style={{ marginTop: "16px" }}>
                  Kwota napiwku:
                </p>
                <AmountWrapper>
                  {defaultAmounts.map(({ amountText, amountValue }, index) => {
                    return (
                      <AmountButton
                        key={index}
                        type="button"
                        isDarkMode={isDarkMode}
                        selected={selectedAmountText === amountText}
                        onClick={() => {
                          setSelectedAmountText(amountText);
                          amountValue && setFieldValue("amount", amountValue);
                        }}
                      >
                        {amountText}
                      </AmountButton>
                    );
                  })}
                </AmountWrapper>
                {isOtherValue && (
                  <>
                    <p className={bodyLargeStrong()}>Podaj kwotę</p>
                    <Input
                      type="number"
                      name="amount"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.amount}
                      isDarkMode={isDarkMode}
                    />
                  </>
                )}
                <Error>
                  {errors.amount && touched.amount && errors.amount}
                </Error>
                <p className={bodyLargeStrong()}>
                  Wiadomość do {isManyAuthors ? "autorów" : "autora"}{" "}
                  (opcjonalnie)
                </p>
                <Input
                  name="message"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}
                  isDarkMode={isDarkMode}
                />
                <p className={bodyLargeStrong()}>Twój adres e-mail</p>
                <Input
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  isDarkMode={isDarkMode}
                />
                <Error>{errors.email && touched.email && errors.email}</Error>
                <CheckboxWrapper>
                  <Input
                    type="checkbox"
                    className={bodyLarge()}
                    name="privacyPolicy"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.privacyPolicy}
                  />
                  <span>
                    Akceptuje&nbsp;
                    <Link to="/info/regulamin">regulamin</Link> i{" "}
                    <Link to="/info/polityka-prywatnosci">
                      politykę prywatności
                    </Link>
                  </span>
                </CheckboxWrapper>
                <Error>
                  {errors.privacyPolicy &&
                    touched.privacyPolicy &&
                    errors.privacyPolicy}
                </Error>
                <PayButton type="submit" className={bodyLarge()}>
                  Zostaw napiwek przez Przelewy24
                </PayButton>
              </form>
            </>
          )}
        </Formik>
      </TipColumn>
    </Wrapper>
  );
};

export default withThemeContext(TipModal);

const Wrapper = styled("div", {
  paddingTop: "78px",
  paddingBottom: "31px",
  paddingLeft: "12px",
  paddingRight: "12px",
  img: {
    width: "100%",
    aspectRatio: 1,
    objectFit: "cover",
  },
  "@bp4": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingTop: "0",
    paddingBottom: "0",
    paddingLeft: "0",
    paddingRight: "0",
    img: {
      width: "705px",
      height: "705px",
      objectFit: "cover",
    },
  },
});

const TipColumn = styled("div", {
  "p:first-of-type": {
    marginTop: "32px",
  },
  "@bp4": {
    "p:first-of-type": {
      marginTop: "0",
    },
    width: "500px",
    paddingTop: "40px",
    paddingRight: "40px",
    paddingBottom: "40px",
    paddingLeft: "72px",
  },
});

const AmountWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "@bp4": {
    gap: "10px",
  },
});

const AmountButton = styled("button", {
  fontSize: 16,
  whiteSpace: "nowrap",
  lineHeight: "24px",
  letterSpacing: "0px",
  fontWeight: "600",
  padding: "12px 22px",
  border: "2px solid rgba(0, 0, 0, 0.05)",
  color: "$grayscale0",
  background: "$grayscale100",
  variants: {
    selected: {
      true: {
        border: "2px solid #459B2B !important",
        backgroundColor: "rgba(69, 155, 43, 0.1)",
        position: "relative",
        "&:after": {
          content: `url(${checkIcon})`,
          position: "absolute",
          width: "16px",
          height: "16px",
          top: "-2px",
          right: "-2px",
          background: "#459B2B",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    },
    isDarkMode: {
      true: {
        borderColor: "rgba(0, 0, 0, 0.05)",
      },
      false: {
        borderColor: "rgba(255, 255, 255, 0.05)",
      },
    },
  },
});

const Textarea = styled("textarea", {
  border: "1px solid rgba(0, 0, 0, 0.05)",
  background: "none",
  fontWeight: 400,
  fontSize: "16px",
  padding: "12px 14px",
  color: "$grayscale0",
  resize: "none",
  width: "100%",
  height: "100px",
  "&:focus-visible": {
    outline: "1px solid $grayscale10",
  },
  variants: {
    isDarkMode: {
      true: {
        borderColor: "rgba(0, 0, 0, 0.05)",
      },
      false: {
        borderColor: "rgba(255, 255, 255, 0.05)",
      },
    },
  },
});

const Input = styled("input", {
  border: "1px solid rgba(0, 0, 0, 0.05)",
  background: "none",
  fontWeight: 400,
  fontSize: "16px",
  padding: "12px 14px",
  color: "$grayscale0",
  width: "100%",
  "&:focus-visible": {
    outline: "1px solid $grayscale10",
  },
  variants: {
    isDarkMode: {
      true: {
        borderColor: "rgba(0, 0, 0, 0.05)",
      },
      false: {
        borderColor: "rgba(255, 255, 255, 0.05)",
      },
    },
  },
});

const CheckboxWrapper = styled("div", {
  display: "flex",
  alignItems: "flex-start",
  marginTop: "16px",
  gap: 10,
  a: {
    color: "$grayscale0",
  },
  input: {
    width: "auto",
    transform: "translateY(30%)",
  },
});

const PayButton = styled("button", {
  background: defaultColors.natematPrimary,
  color: "$grayscale100",
  textAlign: "center",
  border: "none",
  width: "100%",
  padding: "12px 0",
  marginTop: "16px",
});

const MessageTipWrapper = styled("div", {
  paddingLeft: "16px",
  paddingRight: "16px",
  textAlign: "center",
  maxWidth: "800px",
});

const Error = styled("span", {
  color: "$aszdziennikPrimary",
  margin: "0 0 10px 0",
  display: "block",
});

const SuccessTipAuthorWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem",
  textAlign: "left",
  flexDirection: "column",
  h5: {
    margin: 0,
    lineHeight: 1.5,
  },
  p: {
    margin: 0,
  },
  "@bp4": {
    flexDirection: "row",
  },
});

const SuccessTipSeeAlsoWrapper = styled("div", {
  flexDirection: "column",
  display: "flex",
  span: {
    color: "$grayscale0",
    textAlign: "left",
  },
  "@bp4": {
    flexDirection: "row",
  },
  "& .photo-on-left": {
    "@bp4": {
      maxWidth: "50%",
    }
  }
});

const SuccessTipSeeAlsoHeader = styled("div", {
  display: "flex",
  margin: "40px 0 20px 0",
  alignItems: "center",
  "& p": {
    flexShrink: 0,
    margin: "0 16px 0 0",
    color: "$grayscale0",
  },
});

const SuccessTipSeeAlsoBlackLine = styled("div", {
  borderTop: "2px solid $grayscale90",
  width: "100%",
});

const ImagesWrapper = styled("div", {

  "@bp4": {
    display: "flex",
    flexDirection: "row",
    width: "705px",
    height: "705px",
    background: defaultColors.grayscale100,
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",

    "& img": {
      height: "auto",
    },
  },
  variants: {
    numberOfImages: {
      1: {
        "@bp4": {
          "& img": {
            width: "100%",
          },
        },
      },
      2: {
        "& img": {
          width: "50%",
        },
        "@bp4": {
          "& img": {
            width: "50%",
          },
        },
      },
      3: {
        "& img": {
          width: "33.33%",
        },
        "@bp4": {
          "& img": {
            width: "33.33%",
          },
        },
      },
      4: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        "@bp4": {
          display: "flex",
          flexDirection: "row",
          "& img": {
            width: "25%",
          },
        },
      },
    },
  },
});

const LoadingText = styled("h3", {
  padding: "20px 60px",
  textAlign: "center",
});